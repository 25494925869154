// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-duplicates
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Box, Email, Item } from 'react-html-email';
import { useIntl } from 'react-intl';
import Markdown from 'react-remarkable';
import emailStyles from '!!raw-loader!inline-style-loader!./email.css';
import emailStylesRaw from '!!raw-loader!./email.css';

import { localizationData } from '../../../../Intl/setup';
import Config from '../../../../server/config';

import { surveyMessages } from '../../Surveys/SurveyReducer';

import applyStyle from './applyStyle';
// eslint-disable-next-line import/no-duplicates
import emailStylesModule from './email.css';

import csat1 from './images/csat-1.png';
import csat2 from './images/csat-2.png';
import csat3 from './images/csat-3.png';
import csat4 from './images/csat-4.png';
import csat5 from './images/csat-5.png';
import starBgImg from './images/star-bg.png';
import starImg from './images/star.png';

const star = (props, style) => {
  const ret = [];
  const href = props.getUrl(props.num);
  const events = {};
  if (props.onClick) {
    events.onClick = event => props.onClick(event, props.num);
  }
  ret.push(
    <td
      align="center"
      {...style(['.star', props.preview && props.num === 3 ? '.is-hover' : ''], {
        background: `url(${starBgImg}) no-repeat 0 0 / 100% auto`,
        backgroundColor: `${props.buttonColor} !important`,
        backgroundSize: 'cover',
      })}
      valign="middle"
    >
      <a
        {...style(['.star-link'])}
        {...events}
        href={href}
      >
        <img
          alt={props.num}
          src={starImg}
          {...style(['.star-img'], { color: props.buttonColor })}
          dir={props.dir}
        />
      </a>
    </td>,
  );
  return ret;
};

const faces = [csat1, csat2, csat3, csat4, csat5];

const face = (props, style) => {
  const ret = [];
  const href = props.getUrl(props.num);
  const events = {};
  if (props.onClick) {
    events.onClick = event => props.onClick(event, props.num);
  }
  ret.push(
    <td
      align="center"
      {...style(['.face'])}
      valign="middle"
    >
      <a
        {...style(['.face-link'])}
        {...events}
        href={href}
      >
        <img
          alt={props.num}
          src={faces[props.num - 1]}
          {...style(['.face-img'])}
          dir={props.dir}
        />
      </a>
    </td>,
  );
  return ret;
};

const numberButton = (props, style) => {
  const ret = [];
  const href = props.getUrl(props.num);
  const events = {};
  if (props.onClick) {
    events.onClick = event => props.onClick(event, props.num);
  }
  ret.push(
    <Box width="40" align={props.dir === 'rtl' ? 'left' : 'right'} {...style([`.b1-${props.surveyType}`])}>
      <Item align="center" {...style(['.b01'])} valign="middle">
        <a
          {...style(['.b01a'])}
          {...events}
          href={href}
        >
          <span {...style(['.b0110'], { color: props.buttonColor })} dir={props.dir}>
            {props.num}
            <span {...style(['.b0101'])}>{props.sufix ? ` – ${props.sufix}` : ''}</span>
          </span>
        </a>
      </Item>
    </Box>,
  );
  if (props.num > 0) {
    ret.push(
      <Box align={props.dir === 'rtl' ? 'left' : 'right'} {...style(['.b2'])}>
        <Item {...style(['.b22'])} dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
      </Box>,
    );
  }
  return ret;
};

const Wrapper = (props) => {
  const { medium, children } = props;
  if (medium === 'email') {
    return (
      <Email title={props.subject} headCSS={emailStylesRaw} width="100%">
        {props.children}
      </Email>
    );
  }
  return (
    <table
      width="100%"
      align="center"
      cellPadding="0"
      cellSpacing="0"
      border="0"
      valign="top"
      className={emailStylesModule.w}
    >
      <tbody>{children}</tbody>
    </table>
  );
};

Wrapper.propTypes = {
  medium: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

const getProviderEmail = (provider) => {
  switch (provider) {
    case 'Customer.io':
      return '{{customer.email}}';
    case 'Hubspot':
      return '{{contact.email}}';
    case 'Intercom Emails':
      return '{{email}}';
    case 'Mailchimp':
      return '*|EMAIL|*';
    case 'Mailgun':
      return '%recipient_email%';
    case 'Marketo':
      return '{{lead.Email Address}}';
    case 'Sendgrid':
      return '{{email}}';
    case 'Vero':
      return '{{user.email}}';
    case 'Zendesk':
      return '{{ticket.requester.email}}';
    default:
      return '';
  }
};

const getProviderIdentification = (provider) => {
  switch (provider) {
    case 'Customer.io':
      return '?provider=customerio&email={{customer.email}}';
    case 'Hubspot':
      return '?provider=hubspot&name={{contact.firstname}}+{{contact.lastname}}&email={{contact.email}}';
    case 'Intercom Emails':
      return '?provider=intercom&first_name={{name}}+{{last_name}}&email={{email}}&uid={{user_id}}';
    case 'Mailchimp':
      return '?provider=mailchimp&name=*|FNAME|+*|LNAME|*&email=*|EMAIL|*';
    case 'Mailgun':
      return '?provider=mailgun&name=%recipient_fname%+%recipient_lname%&email=%recipient_email%';
    case 'Marketo':
      return '?provider=marketo&name={{lead.First Name}}+{{lead.Last Name}}&email={{lead.Email Address}}&lead_id={{lead.Id}}';
    case 'Sendgrid':
      return '?provider=sendgrid&email={{email}}';
    case 'Vero':
      return '?provider=vero&name={{user.first_name}}+{{user.last_name}}&email={{user.email}}&uid={{user.id}}';
    case 'Zendesk':
      return '?provider=zendesk&email={{ticket.requester.email}}&t_id={{ticket.id}}&agent={{ticket.assignee.name}}';
    default:
      return '';
  }
};

const SurveyEmail = (props) => {
  const {
    medium, logoUrl, logoSize = {}, intro, topColor, buttonColor, rootUrl, responseId, surveyType, shortRootUrl,
    language, preview, surveyCsatType, csatFacesNum, personToken, footer, creatorId, provider, linkId,
  } = props;
  const dummy = responseId === 'yyy' ? '#' : null;

  const { formatMessage } = useIntl();
  const emailMessages = localizationData[language].messages;
  let {
    question, brand,
  } = props;
  brand = brand || formatMessage(surveyMessages['Survey.Brand']);
  question = (question || emailMessages[`${surveyType}.question`])?.replace('{brand}', brand).replace('{marca}', brand);

  const style = medium === 'email' ? applyStyle(emailStyles) : (classes, styleIn) => {
    const st = { ...styleIn };
    const classArray = classes.map(cl => emailStylesModule[cl.replace(/^./, '').trim()]);
    return ({ style: st, className: classArray.join(' ') });
  };
  const getUnsubscribeLink = () => {
    if (provider && linkId) {
      const providerEmail = getProviderEmail(provider);
      if (providerEmail) {
        return `${rootUrl}/l/${linkId}/unsubscribe/${providerEmail}`;
      }
    }
    if (!dummy) {
      return `${rootUrl}/people/${personToken}/unsubscribe`;
    }
    return '#';
  };
  const getUrl = (num) => {
    if (!linkId && dummy) {
      return '#';
    }
    let ret = dummy ? '#' : `${rootUrl}/r/${responseId}/${num || ''}`;
    if (provider && linkId) {
      ret = `${rootUrl}/l/${linkId}/${num}`;
      ret += getProviderIdentification(provider, num);
    }
    if (!dummy && medium !== 'email') {
      ret += ret.indexOf('?') >= 0 ? '&' : '?';
      ret += `medium=${props.medium}`;
    }
    return ret;
  };
  const bestScoreMsg = emailMessages[`${surveyType}.top`];
  const worstScoreMsg = emailMessages[`${surveyType}.bottom`];
  const unsubscribe = emailMessages['nps.unsubscribe'];
  const link = ReactDOMServer.renderToString(
    <a {...style(['.fa'])} href={(dummy && !linkId) || `${rootUrl}?utm_source=promoter-ninja&utm_medium=${medium}&utm_campaign=survey&utm_content=${creatorId}`}>
      Promoter Ninja
    </a>,
  );
  const powered = emailMessages['nps.powered'].replace('{promoter_ninja}', link);
  const dir = ['ar', 'he'].indexOf(language) > -1 ? 'rtl' : 'ltr';
  let numbers = [1, 2, 3, 4, 5];
  if (surveyType === 'nps') {
    numbers = Array.from(Array(11).keys());
  } else if (surveyType === 'csat' && surveyCsatType === 'faces') {
    const numberArrays = [[], [], [1, 5], [1, 3, 5], [1, 2, 4, 5], [1, 2, 3, 4, 5]];
    numbers = numberArrays[csatFacesNum || 5];
  }
  const titleStyle = { borderTop: `6px solid ${topColor}` };
  if (intro) {
    titleStyle.borderBottom = '0';
  }
  const getSuffix = (num) => {
    const bottom = surveyType === 'nps' ? 0 : 1;
    const top = surveyType === 'nps' ? 10 : 5;
    switch (num) {
      case top:
        return bestScoreMsg;
      case bottom:
        return worstScoreMsg;
      default:
        return null;
    }
  };

  let { width, height } = logoSize;
  if (typeof width === 'number' && typeof height === 'number' && width > 0 && height > 0) {
    const ratio = width / height;
    if (ratio > 1) {
      width = Math.min(width, 200);
      height = Math.round(width / ratio);
    } else {
      height = Math.min(height, 80);
      width = Math.round(height * ratio);
    }
  } else {
    width = undefined;
    height = undefined;
  }

  return (
    <Wrapper {...props}>
      <Item align="center" {...style(['.l1'], titleStyle)} dir={dir} valign="middle">
        {logoUrl
          ? (
            <img
              {...style(['.l12'], {
                width: width ? `${width}px` : undefined,
                height: height ? `${height}px` : undefined,
              })}
              src={logoUrl}
              {...{ width, height }}
              alt={brand}
            />
          )
          : <div {...style(['.l11'])}><span {...style(['.val-display-name'])}>{brand}</span></div>
        }
      </Item>
      {intro
        ? (
          <Item align="center" {...style(['.n1'])}>
            <Box {...style(['.i'])} align="center" width="534">
              <Item align="center" {...style(['.n11'])}>
                <pre {...style(['.val-intro-message'])}>
                  {intro}
                </pre>
              </Item>
            </Box>
          </Item>
        ) : null}
      <Item align="center" {...style(['.l2'], surveyType === 'star_five' ? { paddingBottom: '24px' } : '')}>
        <Box {...style(['.i'])} align="center">
          <Item align="center" {...style(['.q'])}>
            <Box align="center" {...style(['.q1'])}>
              <Item align="center" {...style(['.q11'])} dir={dir}>
                {question}
              </Item>
            </Box>
          </Item>
          {surveyType === 'star_five' || (surveyType === 'csat' && surveyCsatType === 'faces')
            ? (
              <Item>
                <Box
                  align="center"
                  {...style([(surveyType === 'star_five' ? '.stars' : '.faces'), preview ? '.is-hover' : ''])}
                >
                  <tr>
                    {numbers.map((num) => {
                      const imageButton = surveyType === 'star_five' ? star : face;
                      return (
                        imageButton({
                          preview,
                          dummy,
                          num,
                          dir,
                          buttonColor,
                          responseId,
                          rootUrl,
                          shortRootUrl,
                          onClick: props.onClick,
                          getUrl,
                          surveyType,
                        }, style)
                      );
                    })}
                  </tr>
                </Box>
              </Item>
            ) : null}
          {surveyType === 'custom' ? (
            <Item {...style(['.b'])}>
              <Box align="center" {...style([`.b1-${surveyType}`])}>
                <Item align="center" {...style(['.b01-custom'])} valign="middle">
                  <a
                    {...style(['.b01a'])}
                    onClick={props.onClick}
                    href={getUrl()}
                  >
                    <span {...style(['.b0110'], { color: buttonColor })} dir={dir}>
                      {emailMessages['nps.submit']}
                    </span>
                  </a>
                </Item>
              </Box>
            </Item>
          )
            : (
              <Item {...style(['.b'])}>
                {numbers.reverse().map(num => (
                  numberButton({
                    dummy,
                    num,
                    dir,
                    buttonColor,
                    sufix: getSuffix(num),
                    responseId,
                    rootUrl,
                    shortRootUrl,
                    onClick: props.onClick,
                    getUrl,
                    surveyType,
                  }, style)
                ))}
              </Item>
            )}

          <Item {...style(['.h'])}>
            <Box {...style(['.ht'])}>
              {surveyType === 'star_five' || (surveyType === 'csat' && surveyCsatType === 'faces')
                ? (
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                )
                : (
                  <tr>
                    <td align="left" {...style(['.htd', '.h1'])}>{dir === 'rtl' ? bestScoreMsg : worstScoreMsg}</td>
                    <td align="right" {...style(['.htd', '.h2'])}>{dir === 'rtl' ? worstScoreMsg : bestScoreMsg}</td>
                  </tr>
                )
              }
            </Box>
          </Item>
        </Box>
      </Item>
      <Item align="center" {...style(['.l3', '.f'])}>
        <Box {...style(['.i'])} align="center">
          <tr>
            <td align="left" {...style(['.fc', '.f1'])} dir={dir} valign="middle">
              <a
                {...style(['.fa'])}
                href={getUnsubscribeLink(dummy)}
              >
                {unsubscribe}
              </a>
            </td>
            <td
              align="right"
              {...style(['.fc', '.f2'])}
              dir={dir}
              valign="middle"
              dangerouslySetInnerHTML={{ __html: powered }}
            />
          </tr>
        </Box>
      </Item>
      {footer
        ? (
          <Item align="center" {...style(['.f'])}>
            <Box {...style(['.i'])} align="center">
              <Item align="center" {...style(['.n11'])}>
                <span {...style(['.fc', '.f3'])}>
                  <Markdown source={footer} />
                </span>
              </Item>
            </Box>
          </Item>
        ) : null}
      <Item>
        <div
          {...style(['.y'], {
            display: 'none', whiteSpace: 'nowrap', font: '15px courier', lineHeight: 0,
          })}
          dangerouslySetInnerHTML={{
            __html: '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;'
              + '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;',
          }}
        />
      </Item>
    </Wrapper>
  );
};

SurveyEmail.defaultProps = {
  question: null,
  intro: null,
  footer: null,
  intl: null,
  rootUrl: Config.rootUrl,
  shortRootUrl: Config.shortRootUrl,
  unsubscribeLink: 'xxx',
  responseId: 'yyy',
  logoUrl: null,
  logoSize: {
    width: undefined,
    height: 33,
  },
  creatorId: null,
  onClick: undefined,
  medium: 'email',
  subject: 'subject',
  preview: false,
  surveyCsatType: 'numbers',
  provider: null,
  linkId: null,
  personToken: null,
};

SurveyEmail.propTypes = {
  preview: PropTypes.bool,
  intl: PropTypes.object,
  surveyType: PropTypes.string.isRequired,
  surveyCsatType: PropTypes.string,
  brand: PropTypes.string.isRequired,
  topColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  question: PropTypes.string,
  subject: PropTypes.string,
  language: PropTypes.string.isRequired,
  intro: PropTypes.string,
  footer: PropTypes.string,
  rootUrl: PropTypes.string,
  shortRootUrl: PropTypes.string,
  unsubscribeLink: PropTypes.string,
  responseId: PropTypes.string,
  logoUrl: PropTypes.string,
  logoSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  creatorId: PropTypes.string,
  onClick: PropTypes.func,
  medium: PropTypes.string,
  csatFacesNum: PropTypes.number.isRequired,
  personToken: PropTypes.string,
  provider: PropTypes.string,
  linkId: PropTypes.string,
};

export default SurveyEmail;

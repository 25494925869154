import { Add, Delete } from '@mui/icons-material';
import {
  Box, Button, ListItemSecondaryAction, ListItemText, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import translations from 'client/modules/Response/pages/AddResponsePages/translations.svg';
import AddLanguagesDialog from 'client/modules/Surveys/pages/CustomizeSurvey/AddLanguagesDialog';
import { scale } from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import { editTranslationsAction, removeLanguageAction } from 'client/modules/Surveys/SurveyActions';
import {
  getAdditionalQuestions, getCustomizations, getId, getInputValue, getSurveyType,
} from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Sticky from 'react-stickynode';
import SVGInline from 'react-svg-inline';
import { localizationData, surveyLanguages } from '../../../../../Intl/setup';

const messages = defineMessages({
  additional: {
    id: 'CustomizeTranslations.Additional',
    defaultMessage: 'Additional {index}: {name}',
  },
  text: {
    id: 'CustomizeTranslations.keys.text',
    defaultMessage: 'text',
  },
  left: {
    id: 'CustomizeTranslations.keys.left',
    defaultMessage: 'left',
  },
  right: {
    id: 'CustomizeTranslations.keys.right',
    defaultMessage: 'right',
  },
});
const CustomizeTranslations = () => {
  const [saving, setSaving] = useState(false);
  const [isAddLanguagesOpen, openAddLanguages, closeAddLanguages] = useOpenClose();
  const [editTranslations, removeLanguage] = useActions([editTranslationsAction, removeLanguageAction]);
  const values = useSelector(state => state.surveys);
  const { formatMessage, locale } = useIntl();
  const baseLang = useSelector(state => getInputValue('surveyLanguage', state) || state.app.i18n.locale);
  const customizations = useSelector(getCustomizations);
  const additionalQuestions = useSelector(getAdditionalQuestions);
  let initialLanguages = Object.keys(customizations.reduce((acc, curr) => ({ ...acc, [curr.language]: true }), {})).sort();
  if (!initialLanguages.includes(baseLang)) {
    initialLanguages = [...initialLanguages, baseLang].sort();
  }
  const [languages, setLanguages] = useState(initialLanguages);
  const surveyType = useSelector(getSurveyType) || 'nps';
  const surveyId = useSelector(getId);
  const [selectedLang, setSelectedLang] = useState(languages?.[0] || false);
  const handleOpenConfirm = useActions(openConfirm);
  const handleChange = (event) => {
    if (event.target.value) {
      setSelectedLang(event.target.value);
    }
  };

  const customizationMap = languages.reduce((acc, curr) => ({ ...acc, [curr]: {} }), {});
  customizations.forEach((c) => {
    if (customizationMap[c.language]) {
      customizationMap[c.language][c.name] = c;
    }
  });

  const getDefault = (lang, key) => {
    let ret = localizationData[lang]?.messages[`${surveyType}.${key}`];
    if (key === 'subject' && !ret) {
      ret = getDefault(lang, 'question');
    }
    return ret;
  };

  const getInitialRows = () => {
    const initialRows = Object.entries({
      fromName: 'From',
      replyTo: 'Reply',
      question: '',
      subject: '',
      intro: '',
      footer: '',
      followUpQuestion: '',
    })
      .map(([key, msgKey]) => {
        return ({
          id: key,
          label: formatMessage({ id: `Survey.${msgKey || upperFirst(key)}` }),
          baseDefault: getDefault(baseLang, key),
          baseLang: customizationMap[baseLang]?.[key]?.value || values[key],
          selectedDefault: getDefault(selectedLang, key) || (['fromName', 'replyTo'].includes(key) ? values[key] : ''),
          originalSelectedLang: customizationMap[selectedLang]?.[key]?.value,
          selectedLang: customizationMap[selectedLang]?.[key]?.value,
          customizationId: customizationMap[selectedLang]?.[key]?._id,
          touched: false,
        });
      });
    const names = {
      text: formatMessage(messages.text),
      scaleMinLabel: formatMessage(messages.left),
      scaleMaxLabel: formatMessage(messages.right),
    };

    additionalQuestions.forEach((q, index) => {
      ['text', 'intro', 'scaleMinLabel', 'scaleMaxLabel', 'buttonLabel', ...(q.optionsAttributes || [])].forEach((key) => {
        const id = `aq-${q._id}-${typeof key === 'object' ? key._id : key}`;
        const base = customizationMap[baseLang][id]?.value
          || q[key]
          || ((typeof key === 'object' && q.optionsAttributes.find(o => o._id === key._id)?.text) || undefined);
        if (key === 'text' || typeof key === 'object' || (q.type === scale && q[key])) {
          initialRows.push({
            id,
            label: formatMessage(messages.additional, { index: index + 1, name: typeof key === 'object' ? key.text : names[key] }),
            baseLang: base,
            selectedDefault: selectedLang === baseLang ? base : '',
            originalSelectedLang: customizationMap[selectedLang]?.[id]?.value,
            selectedLang: customizationMap[selectedLang]?.[id]?.value,
            customizationId: customizationMap[selectedLang]?.[id]?._id,
          });
        }
      });
    });
    return initialRows;
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(getInitialRows());
  }, [selectedLang, customizations]);

  const handleSaveClick = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setSaving(true);
      await editTranslations(surveyId, selectedLang, rows.filter(r => r.selectedLang !== r.originalSelectedLang)
        .map(r => ({
          value: r.selectedLang,
          customizationId: r.customizationId,
          name: r.id,
        })));
    } finally {
      setSaving(false);
    }
  };

  const deleteLanguage = lang => () => {
    if (languages.includes(lang)) {
      const language = surveyLanguages[locale][lang];
      handleOpenConfirm({
        danger: true,
        title: (<FormattedMessage
          id="CustomizeTranslations.Are you sure"
          defaultMessage="Are you sure you want to delete {language}?"
          values={{ language }}
        />),
        text: (<FormattedMessage
          id="CustomizeTranslations.This will remove"
          defaultMessage="This action will remove your current {language} customizations and you won't be able to recover them."
          values={{ language }}
        />),
        onOk: async () => {
          const newLanguages = languages.filter(l => l !== lang);
          if (selectedLang === lang) {
            setSelectedLang(newLanguages[0]);
          }
          await removeLanguage(surveyId, lang);
          setLanguages(newLanguages);
        },
      });
    }
  };

  const handleAddLanguages = (newLanguages) => {
    setLanguages(newLanguages);
    setSelectedLang(newLanguages[0]);
  };

  const additionalLanguages = languages;
  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 1280,
        alignSelf: 'center',
        my: 3,
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
      }}
      id="translations-root"
    >
      {selectedLang ? (
        <form onSubmit={handleSaveClick}>
          <Sticky innerZ={100} enabled bottomBoundary="#translations-root">
            <Grid container sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}>
              <Grid xs={12} sm={6} sx={{ p: 2 }}>
                <Typography>
                  <FormattedMessage
                    id="CustomizeTranslations.Default language"
                    defaultMessage="Default language ({language})"
                    values={{ language: surveyLanguages[locale][baseLang] }}
                  />
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                }}
              >
                <Select
                  value={selectedLang}
                  onChange={handleChange}
                  variant="standard"
                  disableUnderline
                  sx={{ '& .MuiSelect-select': { p: 0 } }}
                  renderValue={value => (
                    <Typography>
                      {value && surveyLanguages[locale][value]}
                    </Typography>
                  )}
                >
                  {additionalLanguages.map((lang, idx) => (
                    <MenuItem key={lang} value={lang} sx={{ pr: 7 }} divider={idx === additionalLanguages.length - 1}>
                      <ListItemText primary={surveyLanguages[locale][lang]} />
                      {lang !== baseLang && (
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={deleteLanguage(lang)}>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </MenuItem>
                  ))}
                  <MenuItem
                    value={false}
                    sx={{ color: 'primary.main' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openAddLanguages();
                    }}
                  >
                    <FormattedMessage
                      id="CustomizeTranslations.Add language"
                      defaultMessage="Add language"
                    />
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={saving}
                >
                  {saving ? (
                    <FormattedMessage
                      id="CustomizeTranslations.Saving"
                      defaultMessage="Saving..."
                    />
                  ) : (
                    <FormattedMessage
                      id="CustomizeTranslations.Save"
                      defaultMessage="Save"
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Divider />
          </Sticky>
          <Grid container>
            <Grid xs={12} sm={6} sx={{ p: 2, flex: 1 }}>
              {rows.map(row => (
                <TextField
                  size="small"
                  margin="normal"
                  label={row.label}
                  readOnly
                  value={row.baseLang || row.baseDefault}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ backgroundColor: 'background.body' }}
                />
              ))}
            </Grid>
            <Grid xs={0} sm={0}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid xs={12} sm={6} sx={{ p: 2 }}>
              {rows.map(row => (
                <TextField
                  key={`${row.id}-${selectedLang}`}
                  size="small"
                  margin="normal"
                  label={row.label}
                  readOnly
                  value={row.selectedLang || row.selectedDefault}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const newRow = { ...row, selectedLang: e.target.value };
                    setRows(rows.map(r => (r.id === row.id ? newRow : r)));
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </form>
      ) : (
        <Box sx={{
          maxWidth: 400,
          alignSelf: 'center',
          textAlign: 'center',
          flexGrow: 1,
          p: 2,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <Box sx={{ color: 'action.active', width: 100, height: 100 }}>
            <SVGInline svg={translations} />
          </Box>
          <Typography>
            <FormattedMessage
              id="CustomizeTranslations.YouDontHave"
              defaultMessage="You don't have any additional languages yet. Click the button below to add some."
            />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={openAddLanguages}
            sx={{ mt: 2 }}
            startIcon={<Add />}
          >
            <FormattedMessage
              id="CustomizeTranslations.Add languages"
              defaultMessage="Add languages"
            />
          </Button>
        </Box>
      )}
      <AddLanguagesDialog
        isOpen={isAddLanguagesOpen}
        close={closeAddLanguages}
        onAdd={handleAddLanguages}
        languages={languages}
      />
    </Paper>
  );
};

export default CustomizeTranslations;

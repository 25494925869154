import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getI18n } from 'client/AppReducer';
import { getToken } from 'client/modules/Auth/AuthReducer';
import {
  freeResponse,
  scale,
  selectMany,
  selectOne,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import { fetchSurveys as fetchSurveysAction } from 'client/modules/Surveys/SurveyActions';
import useActions from 'client/util/useActions';
import isUrl from 'is-url';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { MdDelete } from 'react-icons/md';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import enStrings from 'react-timeago/lib/language-strings/en';
import esStrings from 'react-timeago/lib/language-strings/es';
import { surveyLanguages } from '../../../../../Intl/setup';
import Forbidden from '../../../App/components/Forbidden/Forbidden';
import Layout from '../../../App/components/Layout/LayoutContainer';
import Trasher from '../../../App/components/Trasher/Trasher';
import { getSurveySelector, surveyMessages } from '../../../Surveys/SurveyReducer';

import styles from './ResponsePage.css';

const messages = defineMessages({
  addTags: { id: 'DashboardListItem.addTags', defaultMessage: 'Add tags' },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const findOption = (question, idIn) => {
  const id = idIn.id || idIn;
  return id.match(/^[0-9a-fA-F]{24}$/)
    ? question.optionsAttributes.find(o => o._id === id)?.text || ''
    : id;
};

const ResponsePage = props => {
  const navigate = useNavigate();
  const trasher = useRef();
  const { locale } = useSelector(getI18n);
  const { surveyId } = useParams();
  const survey = useSelector(getSurveySelector(surveyId));
  const fetchSurveys = useActions(fetchSurveysAction);
  const token = useSelector(getToken);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!survey && !loaded) {
      setLoaded(true);
      fetchSurveys(token);
    }
  }, [survey, loaded]);

  if (props.fetchError) {
    return <Forbidden />;
  }
  const { formatMessage } = props.intl;
  const strings = { es: esStrings, en: enStrings };
  const formatter = buildFormatter(strings[props.locale]);
  const { response } = props;
  const {
    returnLinkTo = response ? `/surveys/${response.survey}` : null,
    returnLinkText = response ? formatMessage(surveyMessages.returnToSurvey) : null,
  } = useLocation().state || {};

  const handleAddTag = tag => {
    const tags = [...new Set(props.tags).add(tag)];
    props.setTags(props.token, props.response.survey, props.response.id, tags);
  };

  const handleRemoveTag = tag => {
    const tags = new Set(props.tags);
    tags.delete(tag);
    props.setTags(props.token, props.response.survey, props.response.id, [...tags]);
  };

  const handleChange = (event, value, reason, details) => {
    if (reason === 'createOption' || reason === 'selectOption') {
      handleAddTag(details.option.inputValue || details.option);
    } else if (reason === 'removeOption') {
      handleRemoveTag(details.option);
    } else if (reason === 'clear') {
      props.setTags(props.token, props.response.survey, props.response.id, []);
    }
  };

  const handleDelete = event => {
    trasher.current.explode(event.clientX, event.clientY);
    setTimeout(() => {
      props.deleteResponse(props.token, props.response.survey, props.response.id, () => {
        setTimeout(() => navigate(-1), 1000);
      });
    }, 300);
  };

  const firstOpenQuestion = response.additionalQuestions?.find(q => q.type === freeResponse);
  const firstScaleQuestion = response.additionalQuestions?.find(q => q.type === scale);
  const comment =
    response.surveyType === 'custom'
      ? firstOpenQuestion && response.additionalResponses?.[firstOpenQuestion._id]
      : response.comment;

  const properties = response
    ? {
        ...response.person_properties,
        channel: response.channel,
        ...(response.link ? { link: response.link } : {}),
      }
    : {};
  return (
    <Layout returnLinkText={returnLinkText} returnLinkTo={returnLinkTo}>
      <Trasher ref={trasher} />
      <Grid className={styles['main-grid']} fluid>
        <Row>
          <Col xs={12}>
            {props.response ? (
              <div>
                <div
                  className={`${styles['single-response']} ${styles[response.group]}`}
                  itemScope
                  itemType="http://schema.org/Review"
                  id={response.id}
                >
                  <div className={styles.header}>
                    <h2
                      className={styles.author}
                      itemProp="author"
                      itemScope
                      itemType="http://schema.org/Person"
                    >
                      <Link
                        className={styles.email}
                        itemProp="email"
                        to={
                          response.person
                            ? `/surveys/${response.survey}/people/${response.person.id}`
                            : null
                        }
                        state={{
                          returnLinkTo: 'back',
                          returnLinkText: formatMessage(surveyMessages.returnToResponse),
                        }}
                      >
                        {response.person?.name || response.email || response.phoneNumber}
                      </Link>
                    </h2>
                    <TimeAgo
                      className={styles.time}
                      itemProp="dateCreated"
                      date={new Date(response.created_at * 1000)}
                      formatter={formatter}
                    />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.actions}>
                      <IconButton style={{ fontSize: '24px' }} onClick={handleDelete} size="large">
                        <MdDelete />
                      </IconButton>
                    </div>
                    <div className={styles.innerContent}>
                      {(response.surveyType === 'custom' && !firstScaleQuestion) || (
                        <div
                          className={styles.rating}
                          itemProp="reviewRating"
                          itemScope
                          itemType="http://schema.org/Rating"
                        >
                          <meta itemProp="worstRating" content="0" />
                          <div className={styles['rating-value']} itemProp="ratingValue">
                            {response.score}
                          </div>
                          <meta itemProp="bestRating" content="10" />
                        </div>
                      )}
                      <p
                        className={response.comment ? styles.comment : styles.noComment}
                        itemProp="reviewBody"
                      >
                        {comment || (
                          <FormattedMessage
                            id="ResponsePage.NoComment"
                            defaultMessage="This person did not leave a comment"
                          />
                        )}
                        {response.translations && Object.keys(response.translations).length > 0
                          ? Object.keys(response.translations).map(lang => (
                              <div className={styles.translation} key={lang}>
                                <i>{response.translations[lang]}</i>
                                <small>
                                  <FormattedMessage
                                    id="DashboardListItem.Translated"
                                    defaultMessage="Translated from {source} to {destination}"
                                    values={{
                                      source: surveyLanguages[locale][response.language],
                                      destination: surveyLanguages[locale][lang],
                                    }}
                                  />
                                </small>
                              </div>
                            ))
                          : null}
                      </p>
                    </div>
                    <div>
                      {props.response ? (
                        <Autocomplete
                          fullWidth
                          multiple
                          freeSolo
                          disableCloseOnSelect
                          style={{ marginLeft: '1em' }}
                          size="small"
                          value={props.tags}
                          onChange={handleChange}
                          options={props.allTags}
                          getOptionLabel={option => option.title || option}
                          renderOption={(p, option, { selected }) => (
                            <li {...p}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title || option}
                            </li>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              variant="standard"
                              placeholder={formatMessage(messages.addTags)}
                            />
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                label={option}
                                size="small"
                                {...getTagProps({ index })}
                                className={styles.chip}
                              />
                            ))
                          }
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(option => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                title: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                {!!survey && !isEmpty(response.additionalResponses) && (
                  <div>
                    <h2>
                      <FormattedMessage
                        id="ResponsePage.AdditionalAnswers"
                        defaultMessage="Additional answers"
                      />
                    </h2>
                    <Table className={styles.properties} selectable={false}>
                      <TableBody displayRowCheckbox={false}>
                        {Object.entries(response.additionalResponses || {}).map(
                          ([questionId, valueIn]) => {
                            const question = survey.additionalQuestions?.find(
                              q => q._id === questionId,
                            );
                            let value = valueIn;
                            const comments = [];
                            if (question?.type === selectOne) {
                              comments.push(value.comment);
                              value = findOption(question, value);
                            }
                            if (question?.type === selectMany) {
                              if (value.forEach) {
                                value.forEach(v => comments.push(v.comment));
                              }
                              value =
                                (value.map && value.map(v => findOption(question, v)).join(', ')) ||
                                value;
                            }
                            return (
                              <TableRow key={questionId}>
                                <TableCell>
                                  {question?.text?.replaceAll(/\{\{[^}]+}}/g, '______') || (
                                    <FormattedMessage
                                      id="ResponsePage.DeletedQuestion"
                                      defaultMessage="Deleted Question"
                                    />
                                  )}
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'common.white' }}>
                                  {value}
                                  {comments.map(
                                    ac =>
                                      !ac || (
                                        <blockquote className={styles.additionalComment}>
                                          {ac}
                                        </blockquote>
                                      ),
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  </div>
                )}
                {Object.keys(properties || {}).length > 0 ? (
                  <div>
                    <h2>
                      <FormattedMessage id="ResponsePage.Properties" defaultMessage="Properties" />
                    </h2>
                    <Table className={styles.properties} selectable={false}>
                      <TableBody displayRowCheckbox={false}>
                        {Object.keys(properties).map(property => (
                          <TableRow>
                            <TableCell>{property}</TableCell>
                            <TableCell sx={{ backgroundColor: 'common.white' }}>
                              {(() => {
                                if (typeof properties[property] === 'object') {
                                  return JSON.stringify(properties[property]);
                                }
                                if (
                                  property.toLowerCase() === 'url' ||
                                  isUrl(properties[property])
                                ) {
                                  return properties[property] ? (
                                    <a href={properties[property]}>{properties[property]}</a>
                                  ) : (
                                    properties[property]
                                  );
                                }
                                return properties[property]?.toString();
                              })()}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                ) : null}
              </div>
            ) : null}
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

ResponsePage.defaultProps = {
  response: null,
  tags: [],
  allTags: [],
  fetchError: null,
};

ResponsePage.propTypes = {
  response: PropTypes.shape({
    id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    email: PropTypes.string.isRequired,
    group: PropTypes.oneOf(['promoters', 'passives', 'detractors']),
    person_properties: PropTypes.object,
    score: PropTypes.number.isRequired,
    created_at: PropTypes.number.isRequired,
    survey: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    link: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired,
    person: PropTypes.object.isRequired,
    translations: PropTypes.object,
    additionalResponses: PropTypes.object,
    language: PropTypes.string.isRequired,
    surveyType: PropTypes.string.isRequired,
    additionalQuestions: PropTypes.array.isRequired,
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  allTags: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  setTags: PropTypes.func.isRequired,
  deleteResponse: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  fetchError: PropTypes.string,
};

export default ResponsePage;

import { Box, Button, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { surveyLanguages } from '../../../../../Intl/setup';

const messages = defineMessages({
  addLanguages: {
    id: 'CustomizeTranslations.Add languages',
    defaultMessage: 'Add languages',
  },
  selectLanguages: {
    id: 'CustomizeTranslations.Select languages',
    defaultMessage: 'Select languages',
  },
});

const AddLanguagesDialog = ({
  isOpen, close, onAdd, languages,
}) => {
  const [selectedLanguages, setSelectedLanguages] = React.useState(selectedLanguages);
  useEffect(() => {
    setSelectedLanguages(languages);
  }, [isOpen, languages]);
  const { formatMessage, locale } = useIntl();
  return (
    <Dialog open={isOpen} onClose={close} aria-labelledby="add-languages-title">
      <Box
        component="form"
        sx={{
          p: 2,
          minWidth: 300,
        }}
        onSubmit={e => {
            e.preventDefault();
            onAdd(selectedLanguages);
            close();
        }}
      >
        <Typography variant="h6" id="add-languages-title" component="div">
          <FormattedMessage
            id="CustomizeTranslations.Add languages"
            defaultMessage="Add languages"
          />
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          <FormattedMessage
            id="CustomizeTranslations.AddLanguagesDialogSubtitle"
            defaultMessage="Select languages in which you want to translate your survey. You can add more languages later."
          />
        </Typography>
        <Autocomplete
          fullWidth
          multiple
          freeSolo
          size="small"
          options={Object.keys(surveyLanguages[locale])
            .filter(l => !languages.includes(l))}
          getOptionLabel={option => surveyLanguages[locale][option]}
          onChange={(e, value) => {
            setSelectedLanguages(Object.keys(surveyLanguages[locale])
              .filter(l => [...languages, ...value].includes(l)));
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={formatMessage(messages.addLanguages)}
              placeholder={formatMessage(messages.selectLanguages)}
            />
          )}
        />
        <DialogActions>
          <Button
            onClick={close}
            color="inherit"
          >
            <FormattedMessage
              id="Confirm.Cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            <FormattedMessage
              id="CustomizeTranslations.Add"
              defaultMessage="Add"
            />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

AddLanguagesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
};

export default AddLanguagesDialog;

import { fetchSurveys } from 'client/modules/Surveys/SurveyActions';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { getProfile, getToken } from './modules/Auth/AuthReducer';
import HomePage from './modules/Public/pages/HomePage/HomePageContainer';
import ChooseTypePage from './modules/Surveys/pages/ChooseTypePage/ChooseTypePage';
import SurveysPage from './modules/Surveys/pages/SurveysPage/SurveysPage';

const AuthenticatedHome = () => {
  const surveys = useSelector(getSurveys);
  const token = useSelector(getToken);
  const emailVerified = useSelector(getProfile)?.email_verified;
  const dispatch = useDispatch();

  useEffect(() => {
    if (emailVerified && !surveys) {
      dispatch(fetchSurveys(token));
    }
  }, [token]);

  if (!token) {
    return <HomePage />;
  }

  if (!emailVerified) {
    return <HomePage />;
  }

  if (!surveys) {
    return null;
  }

  if (surveys.length > 1) {
    return <Navigate to="/surveys/list/active" />;
  }
  if (surveys.length === 1) {
    return <Navigate to={`/surveys/${surveys[0].id}`} />;
  }
  return <ChooseTypePage />;
};

AuthenticatedHome.loader = ({ getState, dispatch }) => () => {
  const state = getState();
  const token = getToken(state);
  if (token) {
    return dispatch(fetchSurveys(token));
  }
  return null;
};

export default AuthenticatedHome;

/* eslint-disable no-param-reassign */
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { fetchTags as fetchTagsAction } from 'client/modules/Response/ResponseActions';
import { freeResponse } from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import DashboardChart from 'client/modules/Surveys/pages/DashboardPage/DashboardChart';
import groupsByType from 'client/modules/Surveys/pages/DashboardPage/groupsByType';
import PeoplePropertiesRefinementListFilters from 'client/modules/Surveys/pages/DashboardPage/PeoplePropertiesRefinementListFiltersInt';
import SearchBox from 'client/modules/Surveys/pages/DashboardPage/SearchBox';
import TotalResults from 'client/modules/Surveys/pages/DashboardPage/TotalResults';
import useActions from 'client/util/useActions';
import useListenToSurveySocket from 'client/util/useListenToSurveySocket';
import useOpenClose from 'client/util/useOpenClose';
import useSearchkit from 'client/util/useSearchkit';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Sticky from 'react-stickynode';
import {
  ActionBarRow, Hits, HitsStats, NoHits, Pagination, RangeQuery, RefinementListFilter, ResetFilters, SearchkitProvider, SelectedFilters, SortingSelector,
} from 'searchkit';
import * as Config from '../../../../../server/config';
import withProps from '../../../../util/withProps';
import { setInfo } from '../../../App/AppActions';
import Layout from '../../../App/components/Layout/LayoutContainer';
import NotFound from '../../../App/components/NotFound/NotFound';
import { getRemainingTrialDays, getToken } from '../../../Auth/AuthReducer';
import RefinementListFilterDivider from '../../components/RefinementListFilterDivider/RefinementListFilterDivider';
import SearchDateRange from '../../components/SearchDateRange/SearchDateRange';
import SearchError from '../../components/SearchError';
import SearchExportButton from '../../components/SearchExportButton/SearchExportButton';
import ExistsFieldFilter from '../../components/searchkit/ExistsFieldFilter/ExistsFieldFilter';
import FilteredRefinementList from '../../components/searchkit/FilteredRefinementList/FilteredRefinementList';
import NPS from '../../components/searchkit/NPS/NPSContainer';
import Progress from '../../components/searchkit/Progress/Progress';
import SurveyResponseListItem from '../../components/SurveyResponseListItem/SurveyResponseListItem';
import TrialExpired from '../../components/TrialExpired/TrialExpired';
import { exportResults, fetchSurveys, setSurveyFields } from '../../SurveyActions';
import { getSurvey, surveyMessages } from '../../SurveyReducer';
import styles from './DashboardPage.css';
import ExportProgress from './ExportProgress';

import NoResponsesDisplay from './NoResponses';
import SearchKitContext from './SearchKitContext';
import SecondaryNavTabs from './SecondaryNavTabs';

const messages = defineMessages({
  date: {
    id: 'Dashboard.Date',
    defaultMessage: 'Date',
  },
  'NoHits.NoResultsFound': {
    id: 'NoHits.NoResultsFound',
    defaultMessage: 'No results found.',
  },
  'NoHits.NoResultsFoundDidYouMean': {
    id: 'NoHits.NoResultsFoundDidYouMean',
    defaultMessage: 'No results found for {query}. Did you mean {suggestion}?',
  },
  'NoHits.DidYouMean': {
    id: 'NoHits.DidYouMean',
    defaultMessage: 'Search for {suggestion} instead',
  },
  'NoHits.SearchWithoutFilters': {
    id: 'NoHits.SearchWithoutFilters',
    defaultMessage: 'Search for {query} without filters',
  },
  'NoHits.Error': {
    id: 'NoHits.Error',
    defaultMessage: "We're sorry, an issue occured when fetching your results. Please try again.",
  },
  'NoHits.ResetSearch': {
    id: 'NoHits.ResetSearch',
    defaultMessage: 'Reset Search',
  },
  'reset.clear_all': {
    id: 'reset.clear_all',
    defaultMessage: 'Clear all filters',
  },
  group: {
    id: 'group',
    defaultMessage: 'Group',
    description: 'The group a user belongs to. Can be detractors, passives or promoters.',
  },
  tags: {
    id: 'tags',
    defaultMessage: 'Tags',
  },
  searchPlaceholder: {
    id: 'searchPlaceholder',
    defaultMessage: 'Search comments...',
    description: 'The text that is displayed in the comments searchbox when it is empty.',
  },
  comments: {
    id: 'Dashboard.comments',
    defaultMessage: 'Comments',
  },
  withComments: {
    id: 'Dashboard.withComments',
    defaultMessage: 'With comments',
  },
  withoutComments: {
    id: 'Dashboard.withoutComments',
    defaultMessage: 'Without comments',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  stars: {
    id: 'Dashboard.Tooltip.Stars',
    defaultMessage: '{num, number}-{num, plural, one {star} other {stars}}',
  },
  promoters: {
    id: 'Dashboard.Tooltip.Promoters',
    defaultMessage: 'promoters',
  },
  passives: {
    id: 'Dashboard.Tooltip.Passives',
    defaultMessage: 'passives',
  },
  detractors: {
    id: 'Dashboard.Tooltip.Detractors',
    defaultMessage: 'detractors',
  },
  agree: {
    id: 'Dashboard.Tooltip.Agree',
    defaultMessage: 'agree',
  },
  neutral: {
    id: 'Dashboard.Tooltip.Neutral',
    defaultMessage: 'neutral',
  },
  disagree: {
    id: 'Dashboard.Tooltip.Disagree',
    defaultMessage: 'disagree',
  },
  satisfied: {
    id: 'Dashboard.Tooltip.Satisfied',
    defaultMessage: 'satisfied',
  },
  dissatisfied: {
    id: 'Dashboard.Tooltip.Dissatisfied',
    defaultMessage: 'dissatisfied',
  },
  all: {
    id: 'Dashboard.all',
    defaultMessage: 'All',
  },
  channel: {
    id: 'Dashboard.channel',
    defaultMessage: 'Channel',
  },
  exportResults: {
    id: 'Dashboard.exportResults',
    defaultMessage: 'Export results',
  },
  close: {
    id: 'Close',
    defaultMessage: 'Close',
  },
});

const DashboardPage = () => {
  const intl = useIntl();
  const { id: surveyId, savedSearchId } = useParams();
  const token = useSelector(getToken);
  const survey = useSelector(state => getSurvey(state, surveyId));
  const navigate = useNavigate();
  const fetchTags = useActions(fetchTagsAction);
  const searchkit = useSearchkit(survey);

  const [dateRange, setDateRange] = useState(null);
  const dispatch = useDispatch();
  const [showAddFilters, openAddFilters, closeAddFilters] = useOpenClose();
  const total = useSelector(state => state.surveys.total);
  const currentExport = useSelector(state => state.surveys.currentExport);
  const remaining = useSelector(getRemainingTrialDays);

  const { surveyType } = survey;
  const { formatMessage } = intl;
  const lastAnswer = (survey._id === 'demo' || !survey.hasResponses)
    ? new Date(parseInt(Config.demoTime, 10)).toISOString()
    : survey.lastResponseAt || survey.updatedAt || new Date().toISOString();

  const recencyFilter = RangeQuery('created_at', { gte: `${lastAnswer}||-30d/d` });
  const filtered = !dateRange || !dateRange.length;

  const { hasResponses } = survey;

  useListenToSurveySocket(surveyId);

  useEffect(() => {
    fetchTags(token, surveyId);
  }, [surveyId]);

  if (!survey) {
    return <NotFound />;
  }

  const trialFinished = remaining !== null && remaining <= 0;
  if (trialFinished) {
    dispatch(setInfo(<TrialExpired />));
  }

  const addFilter = ({ key }) => {
    closeAddFilters();
    dispatch(setSurveyFields(token, surveyId, [{ key, hidden: false }])).then(() => dispatch(fetchSurveys(token)));
  };

  const handleExportResults = () => {
    const condition = {
      bool: {
        must: [searchkit.query.query.post_filter, searchkit.query.query.query].filter(v => v),
      },
    };
    dispatch(exportResults(survey.id, condition));
  };

  const exportResultsList = () => {
    navigate(`/surveys/${surveyId}/export`);
  };

  const addEmptyGroups = (buckets) => {
    const groups = groupsByType[surveyType];
    return groups.map((group) => {
      const [num, g] = group.split('-').length > 1 ? group.split('-') : [0, group];
      const bucket = buckets.find(b => b.key === group);
      const label = formatMessage(messages[g], { num });
      return ({
        doc_count: bucket ? bucket.doc_count : 0,
        key: group,
        label,
      });
    });
  };

  const addFiltersOptions = hasResponses && survey.person_properties_types
    ? Object.entries(survey.person_properties_types)
      .filter(([, { hidden }]) => hidden)
      .map(([key]) => ({ key, label: startCase(key) }))
      .sort((v1, v2) => v1.label.toLowerCase().localeCompare(v2.label.toLowerCase()))
    : null;

  return (
    <Layout secondaryNav={<SecondaryNavTabs survey={surveyId} />}>
      {process.env.CLIENT
        ? (
          <SearchKitContext.Provider value={{ searchkit }}>
            <SearchkitProvider searchkit={searchkit} key={surveyId}>
              <div>
                {!survey.hasResponses
                  ? <NoResponsesDisplay survey={survey} importURL={`/surveys/${surveyId}/import`} /> : null
                }
                <Grid className={styles['main-grid']} fluid>
                  {surveyType === 'custom' || (
                    <>
                      <DashboardChart survey={survey} />
                      <Sticky innerZ={100} enabled>
                        <Row className={styles.metrics}>
                          <Col xs={12} sm={3}>
                            <div style={filtered ? { display: 'none' } : null}>
                              <FilteredRefinementList
                                id="totalScoreGroup"
                                title={formatMessage(messages.group)}
                                field="group"
                                operator="OR"
                                listComponent={withProps(NPS, { surveyType, filtered: false })}
                              />
                            </div>
                            <div style={filtered ? null : { display: 'none' }}>
                              <FilteredRefinementList
                                id="totalScoreGroupFiltered"
                                title={formatMessage(messages.group)}
                                field="group"
                                operator="OR"
                                listComponent={withProps(NPS, { surveyType, filtered: true })}
                                filter={recencyFilter}
                              />
                            </div>
                          </Col>
                          <Col xs={12} sm={9}>
                            <RefinementListFilter
                              className={styles['filter--barsGroup']}
                              id="barsGroup"
                              title={formatMessage(messages.group)}
                              field="group"
                              operator="OR"
                              size={surveyType === 'star_five' ? 5 : 3}
                              orderKey="_term"
                              orderDirection="desc"
                              listComponent={Progress}
                              bucketsTransform={addEmptyGroups}
                            />
                          </Col>
                        </Row>
                      </Sticky>
                    </>
                  )}
                  <Row className={styles['main-content']}>
                    <Helmet title={formatMessage(surveyMessages['Survey.Responses.Title'])} />
                    <Col xs={12} sm={3}>
                      <Box sx={{ mt: 1, mb: 3 }}>
                        <HitsStats component={TotalResults} />
                      </Box>
                      <div className={styles.filters}>
                        { surveyType === 'custom' || (
                          <RefinementListFilter
                            id="groupFilter"
                            title={formatMessage(messages.group)}
                            field="group"
                            operator="OR"
                            orderKey="_term"
                            orderDirection="desc"
                            bucketsTransform={addEmptyGroups}
                            size={surveyType === 'star_five' ? 5 : 3}
                          />
                        )}
                        <ExistsFieldFilter
                          id="comments"
                          title={formatMessage(messages.comments)}
                          labelWith={formatMessage(messages.withComments)}
                          labelWithout={formatMessage(messages.withoutComments)}
                          field="comment"
                        />
                        {survey ? <PeoplePropertiesRefinementListFilters survey={survey} /> : null}
                        <RefinementListFilterDivider
                          id="tags"
                          title={formatMessage(messages.tags)}
                          field="tags"
                          operator="OR"
                          size={5}
                        />
                        <RefinementListFilterDivider
                          id="language"
                          title={formatMessage(messages.language)}
                          field="language"
                          operator="OR"
                          size={5}
                        />
                        <RefinementListFilterDivider
                          id="channel"
                          title={formatMessage(messages.channel)}
                          field="channel"
                          operator="OR"
                          size={5}
                        />
                        {hasResponses && (
                          <div>
                            {showAddFilters ? (
                              <div className={styles.addMoreFilters}>
                                <span>
                                  <FormattedMessage
                                    id="Dashboard.AddMoreFilters"
                                    defaultMessage="Add more filters"
                                  />
                                </span>
                                <Select
                                  onChange={addFilter}
                                  options={addFiltersOptions}
                                  styles={{ menu: provided => ({ ...provided, position: 'relative', zIndex: '999 !important' }) }}
                                />
                              </div>
                            ) : (
                              <button type="button" className={styles.addMoreFilters} onClick={openAddFilters}>
                                <FormattedMessage id="Dashboard.AddMoreFilters" />
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} sm={9}>
                      <div>
                        <div style={{ display: 'none' }}>
                          <SortingSelector
                            options={[
                              {
                                label: '', field: 'created_at', order: 'desc', defaultOption: true,
                              },
                            ]}
                          />
                        </div>
                        <Box sx={{
                          display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center', justifyContent: 'flex-end',
                        }}
                        >
                          <div className={styles.searchBar}>
                            <SearchBox
                              autofocus={false}
                              searchOn
                              Change
                              placeholder={formatMessage(messages.searchPlaceholder)}
                              prefixQueryFields={[
                                'email.search^5',
                                'personName.search^5',
                                'comment^1',
                                ...(
                                  survey.additionalQuestions
                                    ?.filter(q => q.type === freeResponse)
                                    .map(q => `additionalResponses.${q._id}`) || []
                                )]}
                              queryFields={['email.search^10']}
                            />
                            <div className={styles.spacer} />
                            <SearchDateRange
                              field="created_at"
                              title={formatMessage(messages.date)}
                              onChange={setDateRange}
                            />
                            <div className={styles.spacer} />
                            <SearchExportButton
                              count={total}
                              onExport={handleExportResults}
                              onListExports={exportResultsList}
                            />
                          </div>
                        </Box>
                        <ActionBarRow>
                          <SelectedFilters />
                          {!savedSearchId && (
                            <ResetFilters
                              translations={{ 'reset.clear_all': formatMessage(messages['reset.clear_all']) }}
                            />
                          )}
                        </ActionBarRow>
                        <List>
                          <Hits
                            mod="sk-hits-list"
                            hitsPerPage={20}
                            itemComponent={SurveyResponseListItem}
                            sourceFilter={['id', 'survey', 'email', 'personName', 'image', 'comment', 'color', 'surveyType',
                              'person_properties', 'group', 'score', 'created_at', 'language', 'phoneNumber', 'translations', 'tags', 'additionalResponses']}
                            scrollTo={false}
                          />
                        </List>
                        <NoHits
                          translations={{
                            'NoHits.NoResultsFound': formatMessage(messages['NoHits.NoResultsFound']),
                            'NoHits.NoResultsFoundDidYouMean': formatMessage(messages['NoHits.NoResultsFoundDidYouMean']),
                            'NoHits.DidYouMean': formatMessage(messages['NoHits.DidYouMean']),
                            'NoHits.Error': formatMessage(messages['NoHits.Error']),
                            'NoHits.ResetSearch': formatMessage(messages['NoHits.ResetSearch']),
                            'NoHits.SearchWithoutFilters': formatMessage(messages['NoHits.SearchWithoutFilters']),
                          }}
                          errorComponent={SearchError}
                          suggestionsField="comment"
                        />
                        <div className={styles.pagination}><Pagination showNumbers /></div>
                        <div className={styles.paginationSmall}><Pagination /></div>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </SearchkitProvider>
          </SearchKitContext.Provider>
        ) : null}
      <ExportProgress currentExport={currentExport && survey.id === currentExport.surveyId ? currentExport : null} />
    </Layout>
  );
};

export const loadSurveys = (store, nextState, replace, callback) => {
  const token = getToken(store.getState());
  if (token) {
    return store.dispatch(fetchSurveys(token)).then(() => {
      callback();
    }).catch((err) => {
      callback();
      throw err;
    });
  }
  callback();
  return null;
};


export default DashboardPage;

import withLocation from "client/util/withLocation";
import SurveyDisabled from "components/SurveyDisabled/SurveyDisabled";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { defineMessages, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { localizationData } from "../../../../../Intl/setup";
import isTouch from "../../../../util/isTouch";
import withParams from "../../../../util/withParams";
import NotFound from "../../../App/components/NotFound/NotFound";
import Spinner from "../../../App/components/Spinner/Spinner";
import csat1 from "../../../Surveys/pages/WebSurveyWidget/images/csat-1.svg";
import csat2 from "../../../Surveys/pages/WebSurveyWidget/images/csat-2.svg";
import csat3 from "../../../Surveys/pages/WebSurveyWidget/images/csat-3.svg";
import csat4 from "../../../Surveys/pages/WebSurveyWidget/images/csat-4.svg";
import csat5 from "../../../Surveys/pages/WebSurveyWidget/images/csat-5.svg";

import {
  newResponseSocket as newResponseSocketAction,
  startTyping as startTypingAction,
  stopTyping as stopTypingAction,
} from "../../../Surveys/SurveyActions";

import {
  addResponse as addResponseAction,
  fetchPublicResponse,
  receivedResponse as receivedResponseAction,
  textareaChange as textareaChangeAction,
} from "../../ResponseActions";
import {
  getAdditionalQuestions,
  getBrand,
  getClient,
  getClientHash,
  getComment,
  getFirstResponse,
  getFollowUpQuestion,
  getLanguage,
  getLogoUrl,
  getSurvey,
  getSurveyCsatType,
  getSurveyType,
  getTokenValid,
  getTopColor,
  getTyping,
  isAlreadyResponded,
  isDisabled,
} from "../../ResponseReducer";

import styles from "./AddResponsePages.css";

const csatFaces = [csat1, csat2, csat3, csat4, csat5];

const messages = defineMessages({
  // eslint-disable-line no-unused-vars
  "nps.tell_us_more": {
    id: "nps.tell_us_more",
    defaultMessage: "Tell us a bit more about why you chose {score}",
  },
  "nps.submit": {
    id: "nps.submit",
    defaultMessage: "Submit",
  },
  "nps.thanks": {
    id: "nps.thanks",
    defaultMessage: "Thanks, we really appreciate your feedback.",
  },
  alreadyResponded: {
    id: "already_responded",
    defaultMessage: "You have already responded to this survey.",
  },
  done: {
    id: "done",
    defaultMessage: "Done",
  },
  nextQuestion: {
    id: "nextQuestion",
    defaultMessage: "Next question",
  },
  onlyOnce: {
    id: "only_once",
    defaultMessage: "You can fill out this form only once.",
  },
  previousQuestion: {
    id: "previousQuestion",
    defaultMessage: "Previous question",
  },
  selectMany: {
    id: "selectMany",
    defaultMessage: "Select one or more options",
  },
  selectOne: {
    id: "selectOne",
    defaultMessage: "Select one option",
  },
});

const AddResponsePage = (props) => {
  const {
    surveyType,
    surveyCsatType,
    tokenValid,
    onSubmit,
    followUpQuestion,
    addResponse,
    survey,
    client,
    clientHash,
    firstResponse,
    response,
    comment,
    location,
    params,
    receivedResponse,
    newResponseSocket,
    language,
    typing,
    startTyping,
    stopTyping,
    textareaChange,
    brand,
    logoUrl,
    topColor,
    alreadyResponded,
    additionalQuestions,
    disabled,
  } = props;
  const [responseSent, setResponseSent] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const navigate = useNavigate();

  const score =
    surveyType === "csat" && surveyCsatType === "faces"
      ? csatFaces[props.score - 1]
      : props.score;
  const sendAnswerNotThrottled = () => {
    if (
      process.env.CLIENT &&
      !responseSent &&
      !alreadyResponded &&
      typeof params.response !== "undefined"
    ) {
      if (window) {
        setResponseSent(true);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const query = Object.fromEntries(urlSearchParams.entries());
        setTimeout(() => {
          receivedResponse(client, survey, firstResponse, clientHash);
          newResponseSocket(response, score);
          addResponse(
            response,
            {
              comment: query.comment || comment,
              score: props.score,
              channel: query.medium || "email",
            },
            false,
          );
        }, 100);
      }
    }
  };
  const sendAnswer = throttle(sendAnswerNotThrottled, 100);
  useEffect(() => {
    if (tokenValid && isTouch()) {
      sendAnswer();
    }
  }, []);

  let channel = "email";
  if (location?.query?.medium) {
    channel = location.query.medium;
  }

  if (disabled || (!onSubmit && !tokenValid && !alreadyResponded)) {
    return (
      <IntlProvider
        locale={language}
        messages={localizationData[language].messages}
        key={language}
      >
        {disabled && <SurveyDisabled />}
        {!onSubmit && !tokenValid && !alreadyResponded && <NotFound />}
      </IntlProvider>
    );
  }

  if (disabled) return <SurveyDisabled />;
  if (!onSubmit && !tokenValid && !alreadyResponded) return <NotFound />;

  const emailMessages = localizationData[language].messages;

  const tellUs = (
    followUpQuestion || emailMessages["nps.tell_us_more"]
  ).replace("{score}", score);
  const submit =
    additionalQuestions.length > 0
      ? emailMessages.nextQuestion
      : emailMessages["nps.submit"];
  const submitting = <Spinner className={styles.spinner} />;
  const powered = emailMessages["nps.powered"].replace(
    "{promoter_ninja}",
    '<a target="_blank" rel="noopener noreferrer" href="https://www.promoter.ninja/">Promoter Ninja</a>',
  );
  const dir = ["ar", "he"].indexOf(language) > -1 ? "rtl" : "ltr";

  const handleTextareaChange = (event) => {
    textareaChange({ comment: event.target.value });
    if (!typing) {
      startTyping(response);
    } else if (event.target.value.length === 0) {
      stopTyping(response);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    } else {
      setSubmitDisabled(true);
      if (typeof params.response !== "undefined") {
        addResponse(
          response,
          {
            score,
            comment,
            channel,
          },
          navigate,
        );
      }
      setTimeout(() => stopTyping(response), 2000); // Update socket clients
    }
  };

  return (
    <div className={styles.main} onMouseMove={sendAnswer}>
      <div className={styles["survey-response"]}>
        <div
          className={styles["survey-response-header"]}
          style={{ borderColor: topColor }}
        >
          {logoUrl ? (
            <img alt={brand} className={styles.l12} src={logoUrl} />
          ) : (
            <div className={styles.l11} dir={dir}>
              {brand}
            </div>
          )}
        </div>
        <div className={styles["survey-response-body"]}>
          <div className={styles["survey-response-body-inner"]}>
            {alreadyResponded ? (
              <>
                <h1 className={styles.title}>
                  {emailMessages.already_responded}
                </h1>
                <p>{emailMessages.only_once}</p>
              </>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className={styles["survey-response-text"]}>
                  <label
                    htmlFor="comment"
                    dir={dir}
                    dangerouslySetInnerHTML={{ __html: tellUs }}
                  />
                </div>
                <div className={styles["survey-response-input"]}>
                  <textarea
                    dir={dir}
                    name="comment"
                    id="comment"
                    rows="5"
                    onChange={handleTextareaChange}
                    defaultValue={comment}
                    autoFocus
                  />
                </div>
                <div className={styles["survey-response-button"]}>
                  <button
                    dir={dir}
                    name="button"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    {submitDisabled ? submitting : submit}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        <div
          dir={dir}
          className={styles["survey-response-footer"]}
          dangerouslySetInnerHTML={{ __html: powered }}
        />
      </div>
    </div>
  );
};

// Specifies the default values for props:
AddResponsePage.defaultProps = {
  comment: "",
  brand: "",
  topColor: "#333",
  language: "en",
  typing: false,
  logoUrl: null,
  client: null,
  clientHash: null,
  survey: null,
  firstResponse: false,
  onSubmit: null,
  followUpQuestion: null,
  additionalQuestions: [],
};

AddResponsePage.propTypes = {
  score: PropTypes.number.isRequired,
  comment: PropTypes.string,
  typing: PropTypes.bool,
  brand: PropTypes.string,
  topColor: PropTypes.string,
  language: PropTypes.string,
  logoUrl: PropTypes.string,
  client: PropTypes.string,
  clientHash: PropTypes.string,
  survey: PropTypes.string,
  response: PropTypes.string.isRequired,
  tokenValid: PropTypes.bool.isRequired,
  textareaChange: PropTypes.func.isRequired,
  startTyping: PropTypes.func.isRequired,
  stopTyping: PropTypes.func.isRequired,
  addResponse: PropTypes.func.isRequired,
  receivedResponse: PropTypes.func.isRequired,
  firstResponse: PropTypes.bool,
  surveyType: PropTypes.string.isRequired,
  surveyCsatType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  followUpQuestion: PropTypes.string,
  newResponseSocket: PropTypes.func.isRequired,
  alreadyResponded: PropTypes.bool.isRequired,
  additionalQuestions: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const tokenValid = !!getTokenValid(state);
  return {
    response: ownProps.params.response,
    score: parseInt(
      typeof ownProps.score === "undefined"
        ? ownProps.params.score
        : ownProps.score,
      10,
    ),
    brand: ownProps.brand || getBrand(state),
    topColor: ownProps.topColor || getTopColor(state),
    language: ownProps.language || getLanguage(state),
    tokenValid,
    comment: getComment(state),
    typing: getTyping(state),
    logoUrl: ownProps.logoUrl || getLogoUrl(state),
    client: getClient(state),
    clientHash: getClientHash(state),
    survey: getSurvey(state),
    surveyType: getSurveyType(state),
    surveyCsatType: getSurveyCsatType(state),
    firstResponse: getFirstResponse(state),
    followUpQuestion:
      ownProps.followUpQuestion ||
      (tokenValid ? getFollowUpQuestion(state) : ""),
    alreadyResponded: isAlreadyResponded(state),
    additionalQuestions: getAdditionalQuestions(state),
    disabled: isDisabled(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  textareaChange(comment) {
    dispatch(textareaChangeAction(comment));
  },
  startTyping(response) {
    dispatch(startTypingAction(response));
  },
  stopTyping(response) {
    dispatch(stopTypingAction(response));
  },
  addResponse(token, response, cb, redirect) {
    dispatch(addResponseAction(token, response, cb, redirect));
  },
  newResponseSocket(response, score) {
    dispatch(newResponseSocketAction(response, score));
  },
  receivedResponse(client, survey, firstResponse, clientHash) {
    dispatch(
      receivedResponseAction({
        client,
        survey,
        firstResponse,
        clientHash,
      }),
    );
  },
});

export const addResponsePageLoader =
  ({ dispatch }) =>
  ({ params: { response } }) =>
    dispatch(fetchPublicResponse(response)).catch(() => {
      return null;
    });

export const AddResponsePageDisconnected = AddResponsePage;

export default withParams(
  connect(mapStateToProps, mapDispatchToProps)(withLocation(AddResponsePage)),
);

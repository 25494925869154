/* eslint-disable jsx-a11y/anchor-is-valid */
import MoreVert from '@mui/icons-material/MoreVert';
import { Box, InputBase } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useChargebee } from 'client/modules/App/ChargebeeContext';
import { useFindFoldersQuery } from 'client/modules/Surveys/folderApiSlice';
import { editSurvey } from 'client/modules/Surveys/SurveyActions';
import { getSurvey } from 'client/modules/Surveys/SurveyReducer';
import callApi from 'client/util/apiCaller';
import InputAutoWidth from 'client/util/InputAutoWidth';
import IconMenu from 'components/IconMenu/IconMenu';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useActionData, useParams } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { toggleUserMenu as toggleUserMenuAction } from '../../../../AppActions';
import { getI18n, isUserMenuOpen } from '../../../../AppReducer';
import { logoutUser as logoutUserAction } from '../../../Auth/AuthActions';
import {
  getProfile,
  getRemainingTrialDays,
  getToken,
  isLoggedIn as isLoggedInSelector,
} from '../../../Auth/AuthReducer';
// Import Style
import styles from './Header.css';

import logo from './promoter-ninja.svg';

const messages = defineMessages({
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  manageSubscription: {
    id: 'manageSubscription',
    defaultMessage: 'Manage subscription',
  },
  settings: {
    id: 'settings',
    defaultMessage: 'Settings',
  },
  emailPrefs: {
    id: 'emailPreferences',
    defaultMessage: 'Email Preferences',
  },
  active: {
    id: 'activeSurveys',
    defaultMessage: 'Active surveys',
  },
  archived: {
    id: 'archivedSurveys',
    defaultMessage: 'Archived surveys',
  },
});

const Header = ({
  toggleUserMenu,
  logoutUser,
  remaining,
  rightNav,
  isLoggedIn,
  profile,
  userMenuOpen,
  hasReturn,
}) => {
  const [surveyName, setSurveyName] = useState('');
  const { formatMessage } = useIntl();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const cbInstance = useChargebee();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  let leftNav = null;
  const survey = useSelector(state => getSurvey(state, params.id || -1)) || {};
  const { data } = useFindFoldersQuery({}, { skip: !survey });
  useEffect(() => {
    if (survey) {
      setSurveyName(survey.name);
    }
  }, [survey]);
  const handleSurveyNameChange = useCallback(
    event => {
      setSurveyName(event.target.value);
    },
    [setSurveyName],
  );
  const handleSurveyRename = useCallback(event => {
    event.preventDefault();
    if (document.activeElement !== event.target) {
      document.activeElement.blur();
    }
    if (survey.name !== surveyName) {
      dispatch(editSurvey(survey.id, { name: surveyName }, token));
    }
  });
  if (params.id && location.pathname.startsWith(`/surveys/${params.id}`)) {
    let folder = survey?.archived
      ? {
          _id: 'archived',
          name: formatMessage(messages.archived),
        }
      : {
          _id: 'active',
          name: formatMessage(messages.active),
        };
    if (survey.folder) {
      folder = data?.find(f => f._id === survey.folder);
    }
    leftNav = (
      <form onSubmit={handleSurveyRename}>
        <ul className={styles.leftNav}>
          {!folder || (
            <li>
              <Link to={`/surveys/list/${folder._id}`} className={styles.headerLink}>
                {folder.name}
              </Link>
            </li>
          )}
          <li>
            <InputAutoWidth
              onBlur={handleSurveyRename}
              onChange={handleSurveyNameChange}
              value={surveyName}
              size="small"
              defaultValue={survey.name}
            />
          </li>
        </ul>
        <input type="submit" style={{ display: 'none' }} />
      </form>
    );
  }
  const closeMenu = useCallback(() => {
    toggleUserMenu(false);
  }, []);

  const openMenu = useCallback(() => {
    toggleUserMenu(true);
  }, []);

  const handleLogout = event => {
    event.preventDefault();
    closeMenu();
    logoutUser();
    navigate('/');
  };

  const authLink =
    location.pathname === '/login' ? (
      <Link to="/sign-up" className={styles.link}>
        <FormattedMessage id="signup" defaultMessage="Sign up" />
      </Link>
    ) : (
      <Link to="/login" className={styles.link}>
        <FormattedMessage id="login" defaultMessage="Login" />
      </Link>
    );

  const goTo = path => () => {
    closeMenu();
    navigate(path);
  };

  const openSubscriptions = () => {
    closeMenu();
    requestAnimationFrame(() => {
      cbInstance.setPortalSession(() => callApi(dispatch, 'users/subscriptions', token, 'GET'));
      const chargebeePortalInstance = cbInstance.createChargebeePortal();
      chargebeePortalInstance.open({
        scheduledCancellationRemoved(data) {
          callApi(dispatch, 'users/subscriptions', token, 'PUT', data);
        },
        subscriptionCancelled(data) {
          callApi(dispatch, 'users/subscriptions', token, 'DELETE', data);
        },
        subscriptionChanged(data) {
          callApi(dispatch, 'users/subscriptions', token, 'PUT', data);
        },
        subscriptionReactivated(data) {
          callApi(dispatch, 'users/subscriptions', token, 'PUT', data);
        },
      });
    });
  };

  const trialNoticeClass = styles.trialNotice + (remaining <= 7 ? ` ${styles.trialWarning}` : '');
  return (
    <div className={styles.header}>
      <Box>
        <Box className={styles.content}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: '1 1 auto',
              flexWrap: 'wrap',
              gap: '1em',
              my: 1,
            }}
          >
            <Box sx={{ flex: '0 1 auto' }}>
              <h1 className={styles['site-title']}>
                <Link className={styles.logo} to={isLoggedIn ? '/surveys/list/active' : '/'}>
                  <SVGInline
                    svg={logo}
                    style={{ width: '180px', height: '30.69px', display: 'inline-block' }}
                  />
                </Link>
              </h1>
            </Box>
            {leftNav ? (
              <Box sx={{ flex: '1 1 auto', textAlign: 'center', whiteSpace: 'nowrap' }}>
                <div className={styles.leftNav} id="leftNav">
                  {leftNav}
                </div>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ flex: '0 1 auto', alignSelf: 'flex-start', my: 1 }}>
            <div className={styles.rightNav} id="rightNav">
              {isLoggedIn && profile.trialEndsAt ? (
                <div className={trialNoticeClass}>
                  <div>
                    <FormattedMessage
                      id="TrialNotice"
                      defaultMessage="Trial ends in {remaining} days"
                      values={{ remaining }}
                    />
                  </div>
                  <Link to="/pricing">
                    <FormattedMessage id="ChooseAPlan" defaultMessage="Choose a plan" />
                  </Link>
                </div>
              ) : null}
              {rightNav}
              {isLoggedIn && profile.email_verified ? (
                <span>
                  <IconMenu
                    className={styles.userMenu}
                    icon={<MoreVert />}
                    isOpen={userMenuOpen}
                    onOpen={openMenu}
                    onClose={closeMenu}
                  >
                    {[
                      ...(isLoggedIn && !profile.trialEndsAt
                        ? [
                            <MenuItem key="subscription" onClick={openSubscriptions}>
                              {formatMessage(messages.manageSubscription)}
                            </MenuItem>,
                          ]
                        : []),
                      <MenuItem key="settings" onClick={goTo('/settings')}>
                        {formatMessage(messages.settings)}
                      </MenuItem>,
                      <MenuItem key="email" onClick={goTo('/email-preferences')}>
                        {formatMessage(messages.emailPrefs)}
                      </MenuItem>,
                      <MenuItem key="logout" onClick={handleLogout}>
                        {formatMessage(messages.logout)}
                      </MenuItem>,
                    ]}
                  </IconMenu>
                  {profile.admin && <a href="/admin">Admin</a>}
                </span>
              ) : (
                authLink
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Header.defaultProps = {
  rightNav: null,
  profile: null,
  remaining: null,
  hasReturn: false,
};

Header.propTypes = {
  remaining: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
  profile: PropTypes.object,
  rightNav: PropTypes.object,
  toggleUserMenu: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  userMenuOpen: PropTypes.bool.isRequired,
  hasReturn: PropTypes.bool,
};

// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    locale: getI18n(state).locale,
    isLoggedIn: isLoggedInSelector(state),
    profile: getProfile(state),
    userMenuOpen: isUserMenuOpen(state),
    remaining: getRemainingTrialDays(state),
  };
}

const mapDispatchToProps = dispatch => {
  return {
    logoutUser() {
      dispatch(logoutUserAction());
    },
    toggleUserMenu(open) {
      dispatch(toggleUserMenuAction(open));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import { Box } from '@mui/material';
import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import useSelectedTab from 'client/util/useSelectedTab';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, useIntl } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';
import Layout from '../../../App/components/Layout/LayoutContainer';
import CustomizeEmail from './CustomizeEmail';

const messages = defineMessages({
  emailTab: {
    id: 'CustomizeSurvey.EmailTab',
    defaultMessage: 'Email',
  },
  smsTab: {
    id: 'CustomizeSurvey.SMSTab',
    defaultMessage: 'SMS',
  },
  thankyouTab: {
    id: 'CustomizeSurvey.ThankyouTab',
    defaultMessage: 'Thank you page',
  },
  questionsTab: {
    id: 'CustomizeSurvey.QuestionsTab',
    defaultMessage: 'Additional Questions',
  },
  targetingTab: {
    id: 'CustomizeSurvey.Targeting',
    defaultMessage: 'Targeting',
  },
  translationsTab: {
    id: 'CustomizeSurvey.TranslationsTab',
    defaultMessage: 'Translations',
  },
});

const CustomizeSurvey = props => {
  const { formatMessage } = useIntl();
  const selectedTab = useSelectedTab();

  return (
    <Layout secondaryNav={!!props.id && <SecondaryNavTabs survey={props.id} />}>
      {props.id ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Tabs
              value={selectedTab}
              textColor="inherit"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={{ mt: 1 }}
              centered
            >
              {[
                'email',
                ...(props.allowSMS ? ['sms'] : []),
                'thankyou',
                'questions',
                'targeting',
                'translations',
              ].map(tab => {
                return (
                  <Tab
                    key={tab}
                    component={Link}
                    label={formatMessage(messages[`${tab}Tab`])}
                    id={`tab-${tab}`}
                    aria-controls={`tabpanel-${tab}`}
                    value={tab}
                    to={tab}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Outlet />
        </>
      ) : (
        <CustomizeEmail />
      )}
    </Layout>
  );
};

CustomizeSurvey.defaultProps = {
  id: null,
  allowSMS: false,
};

CustomizeSurvey.propTypes = {
  id: PropTypes.string,
  allowSMS: PropTypes.bool,
};

export default injectIntl(CustomizeSurvey);

import { Box } from '@mui/material';
import { blue, common, grey, teal } from '@mui/material/colors';
import { esES as coreEsES } from '@mui/material/locale';
import { alpha, createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as pickersEsES } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ThemeProvider from 'client/util/ThemeProvider';
import Confirm from 'components/Confirm/Confirm';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { setLocale } from '../../AppActions';
import favicon from '../../favicon.png';
import styles from './App.css';

const blue100 = blue['100'];
const blue500 = blue['500'];
const blue700 = blue['700'];
const darkBlack = grey[900];
const fullBlack = common.black;
const grey100 = grey['100'];
const grey300 = grey['300'];
const grey400 = grey['400'];
const grey500 = grey['500'];
const white = common.white;

const App = () => {
  const userAgent = useSelector(state => state.app.userAgent);
  const muiTheme = createTheme({
      typography: {
        fontFamily: '"Figtree", sans-serif',
        color: darkBlack,
        body2: {
          fontSize: '1em',
        },
        h1: {
          fontSize: 36,
          lineHeight: '48px',
          fontWeight: 200,
        },
        h2: {
          fontSize: 24,
          lineHeight: '30px',
          fontWeight: 700,
          marginBottom: 12,
        },
        subtitle1: {
          fontSize: '1em',
        },
        subtitle2: {
          fontSize: '0.875em',
          fontWeight: 400,
          color: grey['600'],
        },
      },
      palette: {
        success: {
          main: '#5cb85c',
        },
        primary: blue,
        secondary: teal,
        muted: {
          lighter: grey['300'],
          light: grey['400'],
          main: grey['500'],
          dark: grey['600'],
          contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        canvasColor: white,
        borderColor: grey300,
        disabledColor: alpha(darkBlack, 0.3),
        pickerHeaderColor: blue500,
        clockCircleColor: alpha(darkBlack, 0.07),
        shadowColor: fullBlack,
        background: {
          default: white,
          paper: white,
          body: '#fafaf9',
        },
        text: {
          primary: darkBlack,
          secondary: grey['600'],
          disabled: grey['400'],
        }
      },
      userAgent,
      components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                  color: 'rgba(0, 0, 0, 0.47)',
                }
            }
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              alignItems: 'center',
              width: 66,
              height: 46,
              padding: 12,
            },
            switchBase: {
              padding: 12,
              margin: 2,
              transitionDuration: '300ms',
              color: grey100,
              '&.Mui-checked': {
                '& + .MuiSwitch-track': {
                  opacity: 1,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
            },
            thumb: {
              display: 'block',
              color: '#fff',
              boxSizing: 'border-box',
              width: 18,
              height: 18,
              marginBottom: -2,
            },
            track: ({ theme }) => ({
              display: 'block',
              borderRadius: 24 / 2,
              backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            }),
            checked: {},
            focusVisible: {},
          },
        },
        MuiInput: {
          styleOverrides: {
            underline: {
              '&:before': {
                borderBottom: '1px solid rgb(224, 224, 224)',
              },
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              verticalAlign: 'initial',
              minWidth: 256,
              marginTop: 14,
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              '&:hover, &:focus': { textDecoration: 'none' },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            outlinedSecondary: {
              borderColor: grey['400'],
              color: grey['900'],
              '&:hover, &:focus': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                borderColor: grey['600'],
              },
            },
            containedPrimary: {
              '&:hover, &:focus': {
                boxShadow: 'none',
                color: 'white',
              },
              boxShadow: 'none',
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              '&$selected, &$selected:hover, &$selected:focus': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
                backgroundColor: 'rgba(0, 0, 0, 0.07)',
                fontWeight: 700,
              },
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              borderColor: grey300,
              borderRadius: 4,
            },
            columnSeparator: {
              display: 'none',
            },
            cell: {
              fontWeight: 300,
              border: 0,
              '&:focus-within': {
                outline: 'none',
              },
            },
            columnHeader: {
              fontWeight: 500,
              lineHeight: 1.29,
              color: grey['900'],
            },
            row: {
              backgroundColor: white,
              '&:nth-of-type(odd)': {
                backgroundColor: white,
              },
              '&:hover': {
                backgroundColor: grey['50'],
              },
            },
            columnHeaders: {
              borderColor: grey100,
            },
          },
        },
        MuiTab: {
          styleOverrides: {},
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: 'white',
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
              fontSize: 14,
              padding: 16,
            },
            arrow: {
              color: 'white',
              '&:before': {
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
              },
            },
          },
        },
      },
    },
    pickersEsES,
    dataGridEsES,
    coreEsES,
  );

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const localeMatch = location.pathname.match(/^\/(e[sn])($|\/)/);
    if (localeMatch) {
      dispatch(setLocale(localeMatch[1]));
    }
  }, []);

  const { locale } = useIntl();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <title>Promoter Ninja</title>
        <link rel="shortcut icon" type="image/png" href={favicon}/>
        <body className={styles.body}/>
      </Helmet>
      <GoogleOAuthProvider clientId={process.env.GOOGLE_OAUTH_CLIENT_ID}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={muiTheme}>
                <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterMoment}>
                  <Outlet/>
                  <Confirm/>
                </LocalizationProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;


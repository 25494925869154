import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import useUniqueId from 'client/util/useUniqueId';
import PropTypes from 'prop-types';
import React from 'react';

const SelectField = ({ label, className, ...props }) => {
  const id = useUniqueId();
  return (
    <FormControl variant="standard" className={className}>
      <InputLabel htmlFor={`selectfield-${id}`} shrink>{label}</InputLabel>
      <Select
        variant="standard"
        {...props}
        style={{ marginTop: 14 }}
        id={`selectfield-${id}`}
      />
    </FormControl>
  );
};

SelectField.propTypes = {
  label: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
};

SelectField.defaultProps = {
  className: null,
};

export default SelectField;

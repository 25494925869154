import IntegrationsPage from 'client/modules/Integrations/pages/IntegrationsPage';
import AccountSettingsPage from 'client/modules/Settings/pages/AccountSettingsPage/AccountSettingsPage';
import ApiKeysPage from 'client/modules/Settings/pages/ApiKeysPage/ApiKeysPage';
import IntegrationConfigGSheets from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationConfigGSheets';
import IntegrationConfigHubspot from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationConfigHubspot';
import IntegrationConfigSalesforce from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationConfigSalesforce';
import IntegrationConfigSlack from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationConfigSlack';
import IntegrationConfigWebhooks from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationConfigWebhooks';
import IntegrationConfigZendesk from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationConfigZendesk';
import IntegrationPage from 'client/modules/Settings/pages/IntegrationsPage/IntegrationPage';
import IntegrationsListPage from 'client/modules/Settings/pages/IntegrationsPage/IntegrationsListPage';
import MailProviderPageContainer from 'client/modules/Settings/pages/MailProviderPage/MailProviderPageContainer';
import AdditionalQuestions from 'client/modules/Surveys/pages/CustomizeSurvey/AdditionalQuestions';
import CustomizeEmail from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizeEmail';
import CustomizeSMS from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizeSMS';
import CustomizeThankyou from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizeThankyou';
import CustomizeTranslations from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizeTranslations';
import Targeting from 'client/modules/Surveys/pages/CustomizeSurvey/Targeting';
import TargetingAudiences from 'client/modules/Surveys/pages/CustomizeSurvey/TargetingAudiences';
import TargetingScheduling from 'client/modules/Surveys/pages/CustomizeSurvey/TargetingScheduling';
import TargetingWeb from 'client/modules/Surveys/pages/CustomizeSurvey/TargetingWeb';
import LinkPage from 'client/modules/Surveys/pages/LinkPage/LinkPage';
import ManageTagsPage from 'client/modules/Surveys/pages/ManageTagsPage';
import SharePage from 'client/modules/Surveys/pages/SharePage/SharePage';
import SurveyPeopleContainer from 'client/modules/Surveys/pages/SurveyPeopleContainer';
import React from 'react';
import { Navigate, Route } from 'react-router';
import Authenticated from './Authenticated';
import AnalyzePage from './modules/Analyze/AnalyzePage';
import LoggedRedirect from './modules/App/components/LoggedRedirect';
import SignInAsPage, { onSignInAsEnter } from './modules/Auth/pages/Login/SignInAsPage';
import VerifyEmailPage from './modules/Auth/pages/Login/VerifyEmailPage';
import VerifyEmailResendPage from './modules/Auth/pages/Login/VerifyEmailResendPage';
import EmailPreferencesPage from './modules/EmailPreferences/pages/EmailPreferencesPage/EmailPreferencesPage';
import PeopleList from './modules/People/pages/List/PeopleList';
import PersonView from './modules/People/pages/Person/PersonView';
import PricingPageContainer from './modules/Public/Pricing/pages/PricingPage/PricingPageContainer';
import ResponsePageContainer from './modules/Response/pages/ResponsePage/ResponsePageContainer';
import SearchesList from './modules/Searches/List/SearchesList';
import SettingsPage from './modules/Settings/pages/SettingsPage/SettingsPage';
import CheckoutOkPage, { validateSubscription } from './modules/Subscriptions/pages/CheckoutOkPage/CheckoutOkPageContainer';
import CheckoutPage, { ensurePlan } from './modules/Subscriptions/pages/CheckoutPage/CheckoutPageContainer';
import SubscriptionsPage, { redirect } from './modules/Subscriptions/pages/SubscriptionsPage/SubscriptionsPageContainer';
import OnEnter from './modules/Surveys/components/OnEnter/OnEnter';
import ChooseChannel from './modules/Surveys/pages/ChooseChannel/ChooseChannel';
import ChooseTypePage from './modules/Surveys/pages/ChooseTypePage/ChooseTypePage';
import CustomizeSurvey, { loadSurvey } from './modules/Surveys/pages/CustomizeSurvey/CustomizeSurveyContainer';
import DashboardPage, { loadSurveys } from './modules/Surveys/pages/DashboardPage/DashboardPage';
import ExportResultsPage from './modules/Surveys/pages/ExportResultsPage/ExportResultsPage';
import ImportPeople from './modules/Surveys/pages/ImportPeople/ImportPeople';
import ReviewImport from './modules/Surveys/pages/ReviewImport/ReviewImport';
import SurveysPage from './modules/Surveys/pages/SurveysPage/SurveysPage';
import WebSnippetPage from './modules/Surveys/pages/WebSnippetPage/WebSnippetPage';

// Take the regular authentication & redirect to login from before

const AuthenticatedRoutes = store => (
  <Route element={<Authenticated />}>
    <Route path="sign-in-as/:email" element={<OnEnter func={onSignInAsEnter}><SignInAsPage /></OnEnter>} />
    <Route path="verify-email" element={<VerifyEmailPage />} />
    <Route path="verify-email-resend" element={<VerifyEmailResendPage />} />
    <Route
      path="logged-redirect"
      element={<LoggedRedirect />}
    />
    <Route
      path="surveys/new-choose-type"
      element={<OnEnter func={loadSurveys}><ChooseTypePage /></OnEnter>}
    />
    <Route
      path="surveys/new"
      element={<OnEnter func={loadSurveys}><CustomizeSurvey /></OnEnter>}
    />
    <Route
      path="surveys/:id"
      element={<OnEnter func={loadSurveys}><DashboardPage /></OnEnter>}
    />
    <Route
      path="surveys/:id/saved-searches/:savedSearchId"
      element={<OnEnter func={loadSurveys}><DashboardPage /></OnEnter>}
    />
    <Route
      path="surveys/:id/tags"
      element={<ManageTagsPage />}
      loader={ManageTagsPage.loader(store)}
    />
    <Route
      path="surveys/:id/edit/*"
      element={<OnEnter func={loadSurvey}><CustomizeSurvey /></OnEnter>}
    >
      <Route index element={<Navigate to="email" replace />} />
      <Route path="email" element={<CustomizeEmail />} />
      <Route path="sms" element={<CustomizeSMS />} />
      <Route path="thankyou" element={<CustomizeThankyou />} />
      <Route path="questions" element={<AdditionalQuestions />} />
      <Route path="targeting/*" element={<Targeting />}>
        <Route index element={<Navigate to="scheduling" replace />} />
        <Route path="scheduling" element={<TargetingScheduling />} />
        <Route path="sampling" element={<TargetingWeb />} />
        <Route path="audiences" element={<TargetingAudiences />} />
      </Route>
      <Route path="translations" element={<CustomizeTranslations />} />
    </Route>
    <Route
      path="surveys/:id/searches"
      element={<OnEnter func={loadSurvey}><SearchesList /></OnEnter>}
    />
    <Route
      path="surveys/:id/people"
      element={<OnEnter func={loadSurvey}><PeopleList /></OnEnter>}
    />
    <Route
      path="surveys/:id/people/:personId"
      element={<OnEnter func={loadSurvey}><PersonView /></OnEnter>}
    />
    <Route
      path="surveys/:id/analyze"
      element={<AnalyzePage />}
      loader={AnalyzePage.loader(store)}
    />
    <Route
      path="surveys/:id/export"
      element={<OnEnter func={loadSurvey}><ExportResultsPage /></OnEnter>}
    />
    <Route
      path="surveys/:id/import/*"
      element={<OnEnter func={loadSurvey}><SurveyPeopleContainer /></OnEnter>}
    >
      <Route index element={<ChooseChannel />} />
      <Route path="web" element={<WebSnippetPage />} />
      <Route path="share" element={<SharePage />} />
      <Route path="share/:link" element={<LinkPage />} />
      <Route path=":channel" element={<ImportPeople />} />
      <Route path=":channel/:importId" element={<ReviewImport />} />
    </Route>
    <Route
      path="surveys/:surveyId/responses/:responseId"
      element={<ResponsePageContainer />}
    />
    <Route
      path="surveys"
      element={<OnEnter func={loadSurveys}><SurveysPage /></OnEnter>}
    />
    <Route
      path="surveys/list/:folder?"
      element={<OnEnter func={loadSurveys}><SurveysPage /></OnEnter>}
    />
    <Route
      path="settings"
      element={<Navigate replace to="/settings/developer" />}
    />
    <Route
      path="settings/*"
      loader={SettingsPage.loader(store)}
      element={<SettingsPage />}
    >
      <Route path="developer" element={<ApiKeysPage />} />
      <Route path="mail" element={<MailProviderPageContainer />} />
      <Route path="integrations/*">
        <Route path="" element={<IntegrationsListPage />} />
        <Route path="*" element={<IntegrationPage />}>
          <Route path="gsheets" element={<IntegrationConfigGSheets />} />
          <Route path="hubspot" element={<IntegrationConfigHubspot />} />
          <Route path="salesforce" element={<IntegrationConfigSalesforce />} />
          <Route path="slack" element={<IntegrationConfigSlack />} />
          <Route path="webhooks" element={<IntegrationConfigWebhooks />} />
          <Route path="zendesk" element={<IntegrationConfigZendesk />} />
        </Route>
      </Route>
      <Route path="account" element={<AccountSettingsPage />} />
      <Route path="" element={<Navigate to="developer" replace />} />
    </Route>

    <Route
      path="email-preferences"
      element={<EmailPreferencesPage />}
      loader={EmailPreferencesPage.loader(store)}
    />
    <Route
      path="subscriptions"
      element={<OnEnter func={redirect}><SubscriptionsPage /></OnEnter>}
    />
    <Route
      path="subscriptions/checkout"
      element={<OnEnter func={ensurePlan}><CheckoutPage /></OnEnter>}
    />
    <Route
      path="subscriptions/ok"
      element={<OnEnter func={validateSubscription}><CheckoutOkPage /></OnEnter>}
    />
    <Route
      path="integrations/:type/add"
      element={<IntegrationsPage />}
    />
    <Route path="pricing/:period" element={<PricingPageContainer />} />
    <Route path="pricing" element={<PricingPageContainer />} />
  </Route>
);


export default AuthenticatedRoutes;

/**
 * Root Reducer
 */
import { folderReducer } from 'client/modules/Surveys/folderApiSlice';
import { linkReducer } from 'client/modules/Surveys/linkApiSlice';
import { combineReducers } from 'redux';
import app from './AppReducer';
import app2 from './modules/App/AppReducer';
import confirm from './modules/App/components/Confirm/ConfirmReducer';
import auth from './modules/Auth/AuthReducer';
import content from './modules/Content/ContentReducer';
import emailPreferences from './modules/EmailPreferences/EmailPreferencesReducer';
import { helpReducer } from './modules/Help/helpSlice';
import integrations from './modules/Integrations/IntegrationsReducer';
import pairwise from './modules/Pairwise/PairwiseReducer';
import { peopleReducer } from './modules/People/peopleApiSlice';
import people from './modules/People/PeopleReducer';
import pricing from './modules/Public/Pricing/PricingReducer';
import responses from './modules/Response/ResponseReducer';
import settings from './modules/Settings/SettingsReducer';
import subscriptions from './modules/Subscriptions/SubscriptionReducer';
import { apiKeyReducer } from './modules/Surveys/apiKeysSlice';
import { savedSearchesReducer } from './modules/Surveys/savedSearchApiSlice';
import { surveyAnalyzeReducer } from './modules/Surveys/surveyAnalyzeApiSlice';
import surveys from './modules/Surveys/SurveyReducer';
// Combine all reducers into one root reducer
export default combineReducers({
  apiKeyReducer,
  app,
  app2,
  auth,
  confirm,
  content,
  emailPreferences,
  folderReducer,
  integrations,
  helpReducer,
  linkReducer,
  pairwise,
  people,
  peopleReducer,
  pricing,
  responses,
  savedSearchesReducer,
  settings,
  subscriptions,
  surveyAnalyzeReducer,
  surveys,
});

import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import { defineMessages } from 'react-intl';
import AppError from '../../../server/util/AppError';

// Import Actions
import {
  ADD_PEOPLE,
  ADD_SURVEY,
  BC_EXPORT_DONE,
  BC_EXPORT_ERROR,
  BC_EXPORT_PROGRESS,
  BC_IMPORT_CSV_UPLOADED,
  BC_IMPORT_DONE,
  BC_IMPORT_ERROR,
  BC_IMPORT_INPUT_UPLOADED,
  BC_NEW_RESPONSE,
  BC_STOP_TYPING,
  BC_TYPING,
  CLEAR_ERROR_MESSAGE,
  CLEAR_EXPORT,
  CLEAR_PEOPLE,
  CUSTOMIZATION_INPUT_CHANGE,
  CUSTOMIZE_SURVEY_CHANGE_TAB,
  DELETE_SURVEY_DONE,
  EXPORT_RESULTS_LIST_CB,
  EXPORT_RESULTS_LIST_ERROR,
  EXPORT_RESULTS_LIST_PENDING,
  FILE_UPLOADED,
  LOAD_INPUT_VALUES,
  NEW_SURVEY,
  RELOAD_SEARCH,
  RESET_PEOPLE,
  SEARCH_RESULTS,
  SET_CHART_PERIOD,
  SET_PEOPLE_INPUT,
  SET_SURVEY_SUBMIT_STATE,
  SET_TRANSLITERATE,
  SET_UPLOAD_FILE,
  SURVEY_INPUT_CHANGE,
  SURVEY_STORED,
  SWITCH_SURVEY,
  TIME_SERIES_DONE,
  TOGGLE_BUTTON_PICKER,
  TOGGLE_CHART,
  TOGGLE_SHOW_ENGAGEMENT,
  TOGGLE_TOP_PICKER,
  UPDATE_SURVEY_CB,
  UPLOAD_DONE,
  UPLOAD_START,
  WEB_SNIPPET_STATUS_CB,
} from './SurveyActions';

const defaultPeople = {
  docs: [],
  status: 'Pending',
  csvUploaded: 0,
  inputUploaded: 0,
  total: 0,
  totalPeople: 0,
};

// Initial State
const exportResultsDefault = { loading: false, loaded: false, data: [] };
const initialState = {
  data: undefined,
  filters: {},
  displayTopColorPicker: false,
  displayButtonColorPicker: false,
  _id: null,
  brand: '',
  name: '',
  color: '#333',
  fromName: null,
  replyTo: '',
  subject: '',
  canSubmit: false,
  submitButtonMessage: null,
  uploadFile: null,
  people: defaultPeople,
  importId: null,
  typing: [],
  newResponse: null,
  shouldReloadQuery: false,
  hits: null,
  total: null,
  chartVisible: false,
  chartPeriod: 'month',
  logoUrl: null,
  rand: Math.random(),
  showEngagement: false,
  customizeSurveyTab: 'email',
  customizations: [],
  thankyouLanguage: null,
  thankyouCustomizationGroup: null,
  peopleInput: [],
  transliterate: true,
  totalPeople: null,
  webSnippetActive: false,
  webId: null,
  surveyType: null,
  surveyCsatType: 'faces',
  csatFacesNum: 5,
  currentExport: null,
  exportResults: exportResultsDefault,
  additionalQuestions: [],
  additionalQuestionsPreview: [],
  active: true,
};

// Messages
export const surveyMessages = defineMessages({
  'Survey.Brand': {
    id: 'Survey.Brand',
    defaultMessage: 'Brand/product name',
  },
  'Survey.Name': {
    id: 'Survey.Name',
    defaultMessage: 'Survey internal name',
  },
  'Survey.Name.Hint': {
    id: 'Survey.Name.Hint',
    defaultMessage: 'There is already another survey for this brand, please pick a unique name to identify it',
  },
  'Survey.TopColor': {
    id: 'Survey.TopColor',
    defaultMessage: 'Top bar color',
  },
  'Survey.ButtonColor': {
    id: 'Survey.ButtonColor',
    defaultMessage: 'Button color',
  },
  'Survey.From': {
    id: 'Survey.From',
    defaultMessage: 'From name',
  },
  'Survey.Reply': {
    id: 'Survey.Reply',
    defaultMessage: 'Reply-To:',
  },
  'Survey.Question': {
    id: 'Survey.Question',
    defaultMessage: 'Question',
  },
  'Survey.Subject': {
    id: 'Survey.Subject',
    defaultMessage: 'Subject line',
  },
  'Survey.Intro': {
    id: 'Survey.Intro',
    defaultMessage: 'Intro message',
  },
  'Survey.Footer': {
    id: 'Survey.Footer',
    defaultMessage: 'Footer message',
  },
  'Survey.SendEmailAfter': {
    id: 'Survey.SendEmailAfter',
    defaultMessage: 'Send email after',
  },
  'Survey.ThankyouPageStatic': {
    id: 'Survey.ThankyouPageStatic',
    defaultMessage: 'Same message for everyone',
  },
  'Survey.ThankyouPageDynamic': {
    id: 'Survey.ThankyouPageDynamic',
    defaultMessage: 'Different message based on score',
  },
  'Survey.ThankyouPageCustomizationGroup': {
    id: 'Survey.ThankyouPageCustomizationGroup',
    defaultMessage: 'Score',
  },
  'Survey.ThankyouMessage': {
    id: 'Survey.ThankyouMessage',
    defaultMessage: 'Message',
  },
  'Survey.ThankyouTitle': {
    id: 'Survey.ThankyouTitle',
    defaultMessage: 'Title',
  },
  'Survey.ThankyouLinkText': {
    id: 'Survey.ThankyouLinkText',
    defaultMessage: 'Link text',
  },
  'Survey.ThankyouLinkURL': {
    id: 'Survey.ThankyouLinkURL',
    defaultMessage: 'Link URL',
  },
  'Survey.BasesLanguage': {
    id: 'Survey.BaseLanguage',
    defaultMessage: 'Base language',
  },
  'Survey.Language': {
    id: 'Survey.Language',
    defaultMessage: 'Language',
  },
  'Survey.Next': {
    id: 'Survey.Next',
    defaultMessage: 'Next: import people',
  },
  'Survey.Save': {
    id: 'Survey.Save',
    defaultMessage: 'Save',
  },
  'Survey.Submitting': {
    id: 'Survey.Submitting',
    defaultMessage: 'Submitting...',
  },
  'Survey.SurveyPeople': {
    id: 'Survey.SurveyPeople',
    defaultMessage: 'Survey people',
  },
  'Survey.Create.Title': {
    id: 'Survey.Create.Title',
    defaultMessage: 'Create survey',
  },
  'Survey.Personalize.Title': {
    id: 'Survey.Personalize.Title',
    defaultMessage: 'Personalize survey',
  },
  'Survey.Responses.Title': {
    id: 'Survey.Responses.Title',
    defaultMessage: 'Survey responses',
  },
  'Survey.Import.Title': {
    id: 'Survey.Import.Title',
    defaultMessage: 'Import people',
  },
  'Survey.BasicCustomization': {
    id: 'Survey.BasicCustomization',
    defaultMessage: 'Basic customization',
  },
  'Survey.AdvancedCustomization': {
    id: 'Survey.AdvancedCustomization',
    defaultMessage: 'Advanced customization',
  },
  'Survey.AddLogo': {
    id: 'Survey.AddLogo',
    defaultMessage: 'Add a logo',
  },
  'Survey.AddLogoFormats': {
    id: 'Survey.AddLogoFormats',
    defaultMessage: 'PNG, JPG, GIF',
  },
  'nps.question': {
    id: 'nps.question',
    defaultMessage: 'How likely are you to recommend {brand} to a friend?',
  },
  'ces.question': {
    id: 'ces.question',
    defaultMessage: '{brand} made it easy for me to handle my issue.',
  },
  'csat.question': {
    id: 'csat.question',
    defaultMessage: 'How satisfied were you with {brand}?',
  },
  'star_five.question': {
    id: 'star_five.question',
    defaultMessage: 'How would you rate your experience with {brand}?',
  },
  'ces.subject': {
    id: 'ces.subject',
    defaultMessage: 'How did we do?',
  },
  'nps.top': {
    id: 'nps.top',
    defaultMessage: 'Very likely',
  },
  'nps.bottom': {
    id: 'nps.bottom',
    defaultMessage: 'Not likely',
  },
  'ces.top': {
    id: 'ces.top',
    defaultMessage: 'Strongly agree',
  },
  'ces.bottom': {
    id: 'ces.bottom',
    defaultMessage: 'Strongly disagree',
  },
  'csat.top': {
    id: 'csat.top',
    defaultMessage: 'Very satisfied',
  },
  'csat.bottom': {
    id: 'csat.bottom',
    defaultMessage: 'Very dissatisfied',
  },
  'nps.unsubscribe': {
    id: 'nps.unsubscribe',
    defaultMessage: 'Unsubscribe',
  },
  'nps.powered': {
    id: 'nps.powered',
    defaultMessage: 'Powered by {promoter_ninja}',
  },
  'validations.email': {
    id: 'validations.email',
    defaultMessage: 'This is not a valid email',
  },
  returnToSurvey: {
    id: 'Survey.Return',
    defaultMessage: 'Return to survey',
  },
  returnToResponse: {
    id: 'Survey.ReturnToResponse',
    defaultMessage: 'Return to response',
  },
  chooseADifferentChannel: {
    id: 'Survey.ChooseChannel',
    defaultMessage: 'Choose a different channel',
  },
  channelChoose: {
    id: 'Channel.Choose',
    defaultMessage: 'Choose',
  },
  channelEmailTitle: {
    id: 'Channel.Email.Title',
    defaultMessage: 'Email',
  },
  channelWebTitle: {
    id: 'Channel.Web.Title',
    defaultMessage: 'Web',
  },
  channelLinkTitle: {
    id: 'Channel.Link.Title',
    defaultMessage: 'Link',
  },
  channelSMSTitle: {
    id: 'Channel.SMS.Title',
    defaultMessage: 'SMS',
  },
  offerTransliteration: {
    id: 'Survey.OfferTransliteration',
    defaultMessage: 'Replace characters with GSM-friendly alternatives',
  },
  csatType: {
    id: 'Survey.CsatType',
    defaultMessage: 'Button Type',
  },
  csatTypeNumbers: {
    id: 'Survey.CsatTypeNumbers',
    defaultMessage: 'Numbers',
  },
  csatTypeFaces: {
    id: 'Survey.CsatTypeFaces',
    defaultMessage: 'Faces',
  },
  csatFacesNum: {
    id: 'Survey.CsatFacesNum',
    defaultMessage: 'Steps to show',
  },
  'Survey.FollowUpQuestion': {
    id: 'Survey.FollowUpQuestion',
    defaultMessage: 'Ask open question',
  },
  'nps.tell_us_more': {
    id: 'nps.tell_us_more',
    defaultMessage: 'Tell us a bit more about why you chose {score}',
  },
  'Survey.NextQuestion': {
    id: 'Survey.NextQuestion',
    defaultMessage: 'Next question',
  },
  'Survey.PreventMultipleResponses': {
    id: 'Survey.PreventMultipleResponses',
    defaultMessage: 'Limit to 1 response',
  },
});

/* Selectors */

// Get all surveys
export const getSurveys = state => state.surveys.data;

// Get survey by id
export const getSurvey = (state, id) => {
  if (state.surveys.data) {
    if (id) {
      return state.surveys.data.find(s => s.id === id);
    }
    if (state.surveys.data.length === 1) {
      return state.surveys.data[0];
    }
    throw new AppError({ message: 'More than one survey and no id given' });
  }
  return null;
};

export const getSurveySelector = id => state => getSurvey(state, id);

export const getDisplayTopColorPicker = state => state.surveys.displayTopColorPicker;
export const getDisplayButtonColorPicker = state => state.surveys.displayButtonColorPicker;

export const getInputValue = (id, state) => state.surveys[id];

// Get id of the current survey
export const getId = state => state.surveys._id;

export const canSubmit = state => state.surveys.canSubmit;

export const getSubmitButtonMessage = state => state.surveys.submitButtonMessage;

export const getUploadFile = state => state.surveys.uploadFile;

export const getPeople = state => state.surveys.people;

export const getUploadStatus = state => state.surveys.uploadStatus;

export const getShouldReloadQuery = state => state.surveys.shouldReloadQuery;

export const getTyping = state => state.surveys.typing;

export const isChartVisible = state => state.surveys.chartVisible;

export const getChartPeriod = state => state.surveys.chartPeriod;

export const getTimeSeries = state => (state.surveys.timeSeries && state.surveys.timeSeries[getChartPeriod(state)] ? state.surveys.timeSeries[getChartPeriod(state)].data : null);

export const getLogoUrl = state => state.surveys.logoUrl;

export const getRand = state => state.surveys.rand;

export const getShowEngagement = state => state.surveys.showEngagement;

export const getCustomizeSurveyTab = state => state.surveys.customizeSurveyTab;

export const getSurveyType = state => state.surveys.surveyType;

export const getAdditionalQuestions = state => state.surveys.additionalQuestionsPreview || state.surveys.additionalQuestions;

export const hasAdditionalQuestions = createSelector(
  getAdditionalQuestions,
  aqs => !isEmpty(aqs),
);

export const isAdditionalQuestionsPreview = state => state.surveys.additionalQuestionsPreview;

export const getCustomization = (staticName, thankYou, state, forceLanguage) => {
  const name = thankYou && state.surveys.thankyouStrategy === 'dynamic' ? `${staticName}-${state.surveys.thankyouCustomizationGroup}` : staticName;
  if (state.surveys && state.surveys.customizations) {
    const language = forceLanguage || (thankYou ? state.surveys.thankyouLanguage : state.surveys.surveyLanguage);
    const found = state.surveys.customizations.find(c => c.language === language && c.name === name);
    return found ? found.value : undefined;
  }
  return undefined;
};

export const getPreviewPagesProps = state => ({
  language: state.surveys.surveyLanguage,
  locale: state.surveys.surveyLanguage,
  fromWithDefault: state.surveys.fromName || state.surveys.brand,
  subjectWithDefault: getCustomization('subject', false, state),
  replyTo: state.surveys.replyTo,
  score: state.surveys.newResponse && state.surveys.newResponse.score,
  surveyType: state.surveys.surveyType,
  surveyCsatType: state.surveys.surveyCsatType,
  csatFacesNum: state.surveys.csatFacesNum,
  brand: state.surveys.brand,
  topColor: state.surveys.topColor,
  buttonColor: state.surveys.buttonColor,
  question: getCustomization('question', false, state),
  intro: getCustomization('intro', false, state),
  footer: getCustomization('footer', false, state),
  surveyLanguage: state.surveys.surveyLanguage,
  logoUrl: state.surveys.logoUrl,
  rand: state.surveys.rand,
  followUpQuestion: getCustomization('followUpQuestion', true, state),
});

export const getCustomizations = state => state.surveys.customizations;

export const getPeopleInput = state => state.surveys.peopleInput;

export const getTransliterate = state => state.surveys.transliterate;

export const getTotalPeople = state => state.surveys.totalPeople;

export const getErrorMessage = state => state.surveys.errorMessage || (state.surveys.people && state.surveys.people.errorMessage);

export const getErrors = state => state.surveys.errors || (state.surveys.people && state.surveys.people.errors);

export const getWebSnippetActive = state => state.surveys.webSnippetActive;

export const getWebId = state => state.surveys.webId;

export const getExportResults = state => state.surveys.exportResults || exportResultsDefault;

const SurveyReducer = (state = initialState, action) => {
  const s = { ...state };
  const getSubmitState = st => !!(st.uploadFile || (st.peopleInput && st.peopleInput.length > 0));
  const addPeople = () => {
    const status = s.people && s.people.status;
    const total = s.people && s.people.total;
    s.people = action.people;
    if (s.people.status === 'Pending' && status === 'Done') {
      s.people.status = 'Done';
      s.people.total = total;
    }
    if (s.people.status === 'Error') {
      s.errorMessage = s.people.errorMessage;
      ([s.errors] = s.people.errors);
    }
    s._id = action.surveyId || s._id;
  };
  switch (action.type) {
    case ADD_SURVEY:
      s.data = action.surveys;
      return s;
    case DELETE_SURVEY_DONE:
      s.data = s.data.filter(sur => sur.id !== action.surveyId);
      return s;
    case NEW_SURVEY:
      s._id = null;
      s.brand = '';
      s.name = '';
      s.topColor = '#333';
      s.buttonColor = null;
      s.fromName = '';
      s.replyTo = '';
      s.subject = '';
      s.intro = '';
      s.surveyLanguage = '';
      s.thankyouLanguage = null;
      s.thankyouStrategy = 'static';
      s.thankyouCustomizationGroup = null;
      s.canSubmit = false;
      s.submitButtonMessage = null;
      s.logoUrl = null;
      s.customizations = [];
      s.rand = Math.random();
      s.surveyType = null;
      s.surveyCsatType = null;
      s.csatFacesNum = 5;
      s.webSnippetActive = false;
      s.webId = null;
      s.customizeSurveyTab = 'email';
      s.additionalQuestions = [];
      s.additionalQuestionsPreview = [];
      s.enabled = true;
      return s;
    case TOGGLE_TOP_PICKER:
      s.displayTopColorPicker = typeof action.show === 'undefined' ? !s.displayTopColorPicker : action.show;
      return s;
    case TOGGLE_BUTTON_PICKER:
      s.displayButtonColorPicker = typeof action.show === 'undefined' ? !s.displayButtonColorPicker : action.show;
      return s;
    case SURVEY_INPUT_CHANGE:
      s[action.id] = action.value;
      if (action.id === 'brand') {
        s.name = null;
      }
      return s;
    case CUSTOMIZATION_INPUT_CHANGE: {
      const {
        language, name: staticName, value, thankYou,
      } = action;
      const name = thankYou && s.thankyouStrategy === 'dynamic' ? `${staticName}-${s.thankyouCustomizationGroup}` : staticName;
      const customization = { language, name, value };
      if (!s.customizations) {
        s.customizations = [];
      }
      const idx = s.customizations.findIndex(c => c.language === language && c.name === name);
      if (idx >= 0) {
        s.customizations[idx] = customization;
      } else {
        s.customizations.push(customization);
      }
      return s;
    }
    case SET_SURVEY_SUBMIT_STATE:
      s.canSubmit = action.canSubmit;
      s.submitButtonMessage = action.submitButtonMessage;
      return s;
    case SURVEY_STORED:
      s._id = action.survey.id;
      if (typeof s.data === 'undefined') {
        s.data = [];
      }
      s.data.push(action.survey);
      s.canSubmit = true;
      s.submitButtonMessage = null;
      return s;
    case SET_PEOPLE_INPUT:
      s.peopleInput = action.peopleInput;
      s.canSubmit = getSubmitState(s);
      return s;
    case SET_UPLOAD_FILE:
      s.uploadFile = action.file;
      s.canSubmit = getSubmitState(s);
      return s;
    case UPLOAD_DONE:
      s.canSubmit = false;
      if (action.status === 'Error') {
        s.errorMessage = action.errorMessage;
        ([s.errors] = action.errors);
      } else {
        s.canSubmit = true;
        s.importId = action.importId;
        s.totalPeople = action.totalPeople;
      }
      s.uploadStatus = action.status;
      s.submitButtonMessage = null;
      return s;
    case UPLOAD_START:
      s.canSubmit = false;
      s.importId = action.importId;
      s.totalPeople = null;
      return s;
    case ADD_PEOPLE: {
      addPeople();
      return s;
    }
    case BC_NEW_RESPONSE: {
      s.newResponse = action.response;
      const elem = s.data.find(e => e.id === action.survey);
      if (elem !== null) {
        elem.hasResponses = true;
      }
      s.shouldReloadQuery = true;
      return s;
    }
    case BC_TYPING:
      if (!s.typing.indexOf(action.token) > -1) {
        s.typing.push(action.token);
        s.shouldReloadQuery = true;
      }
      return s;
    case BC_STOP_TYPING:
      if (s.typing.indexOf(action.token) > -1) {
        s.typing = s.typing.filter(v => v !== action.token);
        s.shouldReloadQuery = true;
      }
      return s;
    case SEARCH_RESULTS:
      s.hits = action.hits;
      s.total = action.total;
      s.shouldReloadQuery = false;
      return s;
    case LOAD_INPUT_VALUES: {
      let { survey } = action;
      if (!survey && action.surveyId) {
        survey = s.data.find(e => e.id === action.surveyId);
      }
      if (survey) {
        s._id = survey.id;
        s.brand = survey.brand;
        s.name = survey.name;
        s.topColor = survey.topColor;
        s.buttonColor = survey.buttonColor;
        s.fromName = survey.fromName;
        s.replyTo = survey.replyTo;
        s.subject = survey.subject;
        s.intro = survey.intro;
        s.surveyLanguage = survey.language;
        s.thankyouLanguage = survey.language;
        s.thankyouCustomizationGroup = survey.surveyType === 'nps' ? 'group03' : 'group12';
        s.thankyouStrategy = survey.thankyouStrategy || 'static';
        s.logoUrl = survey.logoUrl ? survey.logoUrl.replace(/\?$/, '') : null;
        s.rand = Math.random();
        s.customizations = survey.customizations;
        s.surveyType = survey.surveyType;
        s.surveyCsatType = survey.surveyCsatType;
        s.csatFacesNum = survey.csatFacesNum;
        s.webSnippetActive = survey.webSnippetActive;
        s.webId = survey.webId;
        s.additionalQuestions = survey.additionalQuestions;
        s.additionalQuestionsPreview = survey.additionalQuestionsPreview;
        s.preventMultipleResponses = survey.preventMultipleResponses;
        s.enabled = survey.enabled;
      }
      return s;
    }
    case RELOAD_SEARCH:
      s.shouldReloadQuery = true;
      return s;

    case TIME_SERIES_DONE:
      s.timeSeries = action.res;
      return s;

    case TOGGLE_CHART:
      s.chartVisible = !s.chartVisible;
      s.shouldReloadQuery = s.chartVisible && (!s.timeSeries || s.timeSeries.length === 0);
      return s;

    case SET_CHART_PERIOD:
      s.chartPeriod = action.period;
      return s;

    case SWITCH_SURVEY:
      s._id = action.id;
      return s;
    case FILE_UPLOADED:
      s.logoUrl = action.url;
      s.rand = Math.random();
      return s;
    case TOGGLE_SHOW_ENGAGEMENT:
      s.showEngagement = action.showEngagement;
      return s;
    case CUSTOMIZE_SURVEY_CHANGE_TAB:
      s.customizeSurveyTab = action.tab;
      return s;
    case SET_TRANSLITERATE:
      s.transliterate = action.transliterate;
      return s;
    case BC_IMPORT_CSV_UPLOADED: {
      return { ...s, people: { ...s.people, csvUploaded: action.uploaded } };
    }
    case BC_IMPORT_INPUT_UPLOADED:
      return { ...s, people: { ...s.people, inputUploaded: action.uploaded } };
    case BC_IMPORT_ERROR:
      return {
        ...s, people: { ...s.people, status: 'Error' }, errorMessage: action.message, errors: action.errors,
      };
    case BC_IMPORT_DONE:
      addPeople();
      return s;
    case RESET_PEOPLE:
      return { ...s, people: defaultPeople };
    case CLEAR_ERROR_MESSAGE:
      s.errorMessage = null;
      if (s.people) {
        s.people.errorMessage = null;
      }
      return s;
    case CLEAR_PEOPLE:
      s.people = defaultPeople;
      return s;
    case WEB_SNIPPET_STATUS_CB:
      s.webSnippetActive = action.status;
      return s;
    case UPDATE_SURVEY_CB:
      s.data = unionBy([action.survey], s.data, 'id');
      s.shouldReloadQuery = true;
      return s;
    case BC_EXPORT_PROGRESS:
    case BC_EXPORT_DONE:
    case BC_EXPORT_ERROR:
      return { ...s, currentExport: { ...action } };
    case CLEAR_EXPORT:
      return { ...s, currentExport: null };
    case EXPORT_RESULTS_LIST_PENDING: {
      return { ...s, exportResults: { loading: true, loaded: false, data: [] } };
    }
    case EXPORT_RESULTS_LIST_CB: {
      return {
        ...s,
        exportResults: {
          loading: false, loaded: true, data: action.exportRequests, error: null,
        },
      };
    }
    case EXPORT_RESULTS_LIST_ERROR: {
      return {
        ...s,
        exportResults: {
          loading: false, loaded: false, data: [], error: action.message,
        },
      };
    }
    default:
      return state;
  }
};

// Export Reducer
export default SurveyReducer;

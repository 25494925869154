import { InfoRounded, QuestionMark } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box, CardContent, FormControlLabel, FormHelperText, InputLabel, Typography,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import FieldWithUnit from 'client/modules/Surveys/pages/CustomizeSurvey/FieldWithUnit';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  sampling: {
    id: 'TargetingSampling.Sampling',
    defaultMessage: 'Sampling',
  },
  subtitle: {
    id: 'TargetingSampling.Subtitle',
    defaultMessage: 'Control the percentage of visitors who see your survey.',
  },
  seconds: {
    id: 'TargetingSampling.Seconds',
    defaultMessage: 'Seconds',
  },
  timeDelayOnPage: {
    id: 'TargetingSampling.TimeDelayOnPage',
    defaultMessage: 'Time delay on page',
  },
  adaptiveSampling: {
    id: 'TargetingSampling.AdaptiveSampling',
    defaultMessage: 'Use adaptive sampling',
  },
  adaptiveSamplingTooltip: {
    id: 'TargetingSampling.AdaptiveSamplingTooltip',
    defaultMessage: '<p>We automatically take care of sampling to optimize the rate of feedback. </p><p> This is based on a number of factors, including your current plan and site traffic. </p><p>  As we learn more about the traffic to your site, the sampling rate will update in real time, ensuring you receive a steady stream of feedback throughout the entire month. </p><p>  This makes it easier to track changes over time, and also helps to ensure the feedback is unbiased.</p>',
  },
  sendEmailAfterWebSurvey: {
    id: 'TargetingSampling.SendEmailAfterWebSurvey',
    defaultMessage: 'Send email if web survey is not answered',
  },
  emailSendDelay: {
    id: 'TargetingSampling.EmailSendDelay',
    defaultMessage: 'Send email after',
  },
  dismissThrottle: {
    id: 'TargetingScheduling.DismissThrottle',
    defaultMessage: 'If not answered, resurvey after',
  },
});

const TargetingWeb = () => {
  const { register, setValue, watch } = useFormContext();
  const { formatMessage } = useIntl();
  const adaptiveSampling = watch('adaptiveSampling');
  const sendEmailAfterWebSurvey = watch('sendEmailAfterWebSurvey');
  return (
    <CardContent sx={{
      borderWidth: 0,
      borderColor: 'muted.lighter',
      borderStyle: 'solid',
      borderTopWidth: 1,
    }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
        {formatMessage(messages.subtitle)}
      </Typography>
      <Box sx={{
        display: 'grid', gridTemplateColumns: 'minmax(100px, max-content) repeat(2, min-content)', gap: 2, alignItems: 'center',
      }}
      >
        <InputLabel id="timeDelayOnPage-label" htmlFor="timeDelayOnPage">
          {formatMessage(messages.timeDelayOnPage)}
        </InputLabel>
        <OutlinedInput
          {...register('timeDelayOnPage', { valueAsNumber: true })}
          id="timeDelayOnPage"
          aria-labelledby="timeDelayOnPage-label"
          size="small"
          variant="outlined"
          type="number"
          inputProps={{
            min: 0,
            max: 10000,
          }}
          sx={{ minWidth: 60, '& input': { textAlign: 'right', pr: 0 } }}
        />
        <Typography>{formatMessage(messages.seconds)}</Typography>
        <FieldWithUnit name="dismissThrottle" messages={messages} />
        <Box sx={{
          gridColumn: '1 / span 3', display: 'flex', alignItems: 'center', mt: 2,
        }}
        >
          <FormControlLabel
            control={(
              <Controller
                type="checkbox"
                name="adaptiveSampling"
                render={({ field: { value, name, onChange } }) => {
                  return (
                    <Switch
                      component="div"
                      checked={value}
                      onChange={(event, val) => {
                        setValue(name, val);
                        return onChange({ target: { value: val } });
                      }}
                    />
                  );
                }}
              />
            )}
            label={formatMessage(messages.adaptiveSampling)}
          />
          <Tooltip title={formatMessage(messages.adaptiveSamplingTooltip, { p: msg => <p>{msg}</p> })}>
            <InfoIcon color="disabled" fontSize="small" />
          </Tooltip>
        </Box>
        {!adaptiveSampling && (
          <>
            <InputLabel id="sampling-label" htmlFor="sampling">
              {formatMessage(messages.sampling)}
            </InputLabel>
            <OutlinedInput
              {...register('sampling', { valueAsNumber: true })}
              id="sampling"
              aria-labelledby="sampling-label"
              size="small"
              variant="outlined"
              type="number"
              inputProps={{
                min: 0,
                max: 100,
              }}
              sx={{ minWidth: 60, '& input': { textAlign: 'right', pr: 0 } }}
            />
            <Typography>%</Typography>
          </>
        )}
        <Box sx={{
          gridColumn: '1 / span 3', display: 'flex', alignItems: 'center', mt: 2,
        }}
        />
        <Box sx={{ gridColumn: '1 / span 3', display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={(
              <Controller
                type="checkbox"
                name="sendEmailAfterWebSurvey"
                render={({ field: { value, name, onChange } }) => {
                  return (
                    <Switch
                      component="div"
                      checked={value}
                      onChange={(event, val) => {
                        setValue(name, val);
                        return onChange({ target: { value: val } });
                      }}
                    />
                  );
                }}
              />
            )}
            label={formatMessage(messages.sendEmailAfterWebSurvey)}
          />
        </Box>
        {sendEmailAfterWebSurvey && (
          <FieldWithUnit name="emailSendDelay" messages={messages} />
        )}
      </Box>
    </CardContent>
  );
};


export default TargetingWeb;

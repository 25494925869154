const toBoolean = require('./util/toBoolean');

const config = {
  availableLanguages: ['en', 'es'],
  defaultLanguage: 'en',
  production: process.env.NODE_ENV === 'production',
  development: process.env.NODE_ENV === 'development',
  sendEmailOnDev: toBoolean(process.env.SEND_EMAIL_ON_DEV, false),
  mongoURL: process.env.MONGO_URL || process.env.MONGODB_URI || 'mongodb://localhost:27017/mern-starter?socketTimeoutMS=90000',
  mongoURLOld: process.env.MONGO_URL_OLD || process.env.MONGODB_URI_OLD || process.env.MONGO_URL || process.env.MONGODB_URI || 'mongodb://localhost:27017/mern-starter?socketTimeoutMS=90000',
  elasticURL: process.env.ELASTIC_URL || process.env.SEARCHBOX_URL || 'http://localhost:9200',
  publicPort: Number(process.env.SERVER_PUBLIC_PORT) || 443,
  port: Number(process.env.PORT) || 443,
  protocol: (Number(process.env.SERVER_PUBLIC_PORT) || 443) === 443 ? 'https' : 'http',
  address: process.env.SERVER_ADDRESS || 'www.promoter.ninja',
  shortenerAddress: process.env.SHORTENER_SERVER_ADDRESS || 't.ptnj.io',
  sendGridApiKey: process.env.SENDGRID_API_KEY,
  sendGridDefaultDomain: process.env.SENDGRID_DEFAULT_DOMAIN || 'em.promoter.ninja',
  sendGridVerificationKey: process.env.SENDGRID_VERIFICATION_KEY,
  mailGunApiKey: process.env.MAILGUN_API_KEY,
  mailGunDefaultDomain: process.env.MAILGUN_DEFAULT_DOMAIN || 's.promoter.ninja',
  sesDefaultDomain: process.env.SES_DEFAULT_DOMAIN || 'email.m.promoter.ninja',
  sesArn: process.env.SES_SOURCE_ARN || 'arn:aws:ses:eu-west-1:199186692721:identity/m.promoter.ninja',
  mailServiceType: process.env.MAIL_SERVICE_TYPE || 'SENDGRID',
  mailGunApiHost: process.env.MAILGUN_API_HOST || '',
  chargeBeeApiKey: process.env.CHARGEBEE_API_KEY,
  chargeBeeSite: process.env.CHARGEBEE_SITE || 'promoter-ninja',
  googleOauthClientId: process.env.GOOGLE_OAUTH_CLIENT_ID,
  slack: {
    clientId: process.env.SLACK_CLIENT_ID,
    clientSecret: process.env.SLACK_CLIENT_SECRET,

  },
  gsheets: {
    clientId: process.env.GOOGLE_OAUTH_CLIENT_ID,
    clientSecret: process.env.GOOGLE_OAUTH_CLIENT_SECRET,
  },
  hubspot: {
    clientId: process.env.HUBSPOT_CLIENT_ID,
    clientSecret: process.env.HUBSPOT_CLIENT_SECRET,
  },
  zendesk: {
    clientId: process.env.ZENDESK_CLIENT_ID,
    clientSecret: process.env.ZENDESK_CLIENT_SECRET,
  },
  salesforce: {
    clientId: process.env.SALESFORCE_CLIENT_ID,
    clientSecret: process.env.SALESFORCE_CLIENT_SECRET,
  },
  aws: {
    S3_LOGOS_BUCKET: 'l.promoter.ninja',
    S3_IMPORTS_BUCKET: 'import.promoter.ninja',
    S3_EXPORTS_BUCKET: 'exports.promoter.ninja',
  },
  logoHost: 'i.promoter.ninja',
  defaultEmailProps: {
    from: {
      name: 'Promoter Ninja',
      email: 'hello@promoter.ninja',
    },
    subject: ' ',
    html: ' ',
  },
  defaultMinSurveyInterval: '6 month',
  sentryDsn: process.env.SENTRY_DSN,
  sentryPublicDsn: process.env.SENTRY_PUBLIC_DSN,
  maxFileSize: 5000, // In kb
  cid: process.env.CID,
  messenteApiUsername: process.env.MESSENTE_API_USERNAME,
  messenteApiPassword: process.env.MESSENTE_API_PASSWORD,
  sendSMS: toBoolean(process.env.SEND_SMS, true),
  intercomSecret: process.env.INTERCOM_SECRET,
  demoTime: process.env.DEMO_TIME,
  msTranslateAPIKey: process.env.MS_TRANSLATE_API_KEY,
  tokenMaxAge: '30m',
  sessionLength: '8d',
  signedURLSecret: process.env.SIGNED_URL_SECRET,
  defaultSampling: 1.0,
  minAdaptiveSamplingSurveys: 5,
  assetsRootUrl: process.env.NODE_ENV === 'production' ? 'https://cdn.promoter.ninja/' : '/static/',
  translationService: process.env.TRANSLATION_SERVICE || 'aws',
  configFilesDir: process.env.CONFIG_FILES_DIR,
};
const urlPort = [80, 443].indexOf(config.publicPort) >= 0 ? '' : `:${config.publicPort}`;
config.rootUrl = `${config.protocol}://${config.address}${urlPort}`;
config.shortRootUrl = `${config.protocol}://${config.shortenerAddress}${urlPort}`;
config.apiUrl = (typeof window === 'undefined' || process.env.NODE_ENV === 'test')
  ? process.env.BASE_URL || (`${config.rootUrl}/api`) : '/api';
module.exports = config;
config.serverSideRendering = typeof process.env.SSR === 'undefined' ? config.production : toBoolean(process.env.SSR);
config.ReCaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;
config.ReCaptchaSecretKey = process.env.RECAPTCHA_SECRET_KEY;
config.SalesForceManagedAppVersion = process.env.SALESFORCE_MANAGED_APP_VERSION;

import { Settings } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ListIcon from '@mui/icons-material/List';
import { Box, CardActionArea, CardActions, Checkbox, TextField, Tooltip } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardText from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import { getI18n } from 'client/AppReducer';
import { getToken } from 'client/modules/Auth/AuthReducer';
import { setTags as setTagsAction } from 'client/modules/Response/ResponseActions';
import { getAllTags } from 'client/modules/Response/ResponseReducer';
import { freeResponse, scale } from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import useActions from 'client/util/useActions';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import enStrings from 'react-timeago/lib/language-strings/en';
import esStrings from 'react-timeago/lib/language-strings/es';
import { surveyLanguages } from '../../../../../Intl/setup';

import md5 from '../../../../util/md5';
import { getTyping } from '../../SurveyReducer';

import Typing from '../Typing/Typing';

import styles from './SurveyResponseListItem.css';

const messages = defineMessages({
  backToResponses: {
    id: 'DashboardListItem.returnToResponses',
    defaultMessage: 'Return to responses',
  },
  additionalAnswers: {
    id: 'DashboardListItem.additionalAnswers',
    defaultMessage: '{count} additional answers',
  },
  addTags: {
    id: 'DashboardListItem.addTags',
    defaultMessage: 'Add tags',
  },
  autoTag: {
    id: 'DashboardListItem.Autotag',
    defaultMessage: 'Autotag',
  },
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const SurveyResponseListItem = ({ result }) => {
  const [currentTags, setCurrentTags] = useState(result._source.tags);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { locale } = useSelector(getI18n);
  const typing = useSelector(getTyping);
  const strings = { es: esStrings, en: enStrings };
  const formatter = buildFormatter(strings[locale]);
  const allTags = useSelector(getAllTags);
  const setTags = useActions(setTagsAction);
  const token = useSelector(getToken);

  const source = { ...result, ...result._source };

  const autotag = () => {
    navigate(`/surveys/${source.survey}/tags`);
  };

  const dir = ['ar', 'he'].indexOf(source.language) > -1 ? 'rtl' : 'ltr';
  const style = (source.group?.indexOf('-') > -1 ? 's' : '') + source.group;
  let avatar;
  if (source.image && source.image.length > 4) {
    avatar = <Avatar src={source.image} />;
  } else if (source.email) {
    avatar = <Avatar src={`https://www.gravatar.com/avatar/${md5(source.email)}?d=mm`} />;
  } else if (source.person.name && source.person.name.length > 1) {
    avatar = <Avatar backgroundColor={source.color}>{source.person.name[0]}</Avatar>;
  } else {
    avatar = <Avatar src={`https://www.gravatar.com/avatar/${md5('')}?d=mm`} />;
  }

  const handleAddTag = async tag => {
    const newTags = [...new Set(currentTags).add(tag)];
    await setTags(token, source.survey, source.id, newTags);
    setCurrentTags(newTags);
  };

  const handleRemoveTag = async tag => {
    const tags = new Set(currentTags);
    tags.delete(tag);
    const newTags = [...tags];
    await setTags(token, source.survey, source.id, newTags);
    setCurrentTags(newTags);
  };

  const handleChange = async (event, value, reason, details) => {
    if (reason === 'createOption' || reason === 'selectOption') {
      await handleAddTag(details.option.inputValue || details.option);
    } else if (reason === 'removeOption') {
      await handleRemoveTag(details.option);
    } else if (reason === 'clear') {
      await setTags(token, source.survey, source.id, []);
      setCurrentTags([]);
    }
  };

  const firstOpenQuestion = source.additionalQuestions?.find(q => q.type === freeResponse);
  const firstScaleQuestion = source.additionalQuestions?.find(q => q.type === scale);
  const hasAdditionalAnswers = !isEmpty(source.additionalResponses);
  return (
    <Card
      itemScope
      itemType="http://schema.org/Review"
      id={source.id}
      style={{ borderRadius: 0, boxShadow: 'none' }}
      className={`${styles['single-response']} ${styles[style]}`}
    >
      <CardActionArea
        onClick={() =>
          navigate(`/surveys/${source.survey}/responses/${source._id}`, {
            state: {
              returnLinkTo: 'back',
              returnLinkText: formatMessage(messages.backToResponses),
            },
          })
        }
      >
        <CardHeader
          title={source.person.name || source.email || source.phoneNumber}
          subheader={
            <TimeAgo itemProp="dateCreated" date={source.created_at * 1000} formatter={formatter} />
          }
          avatar={avatar}
          action={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'muted.main',
              }}
            >
              {hasAdditionalAnswers && (
                <Tooltip
                  title={formatMessage(messages.additionalAnswers, {
                    count: Object.keys(source.additionalResponses).length,
                  })}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    {Object.keys(source.additionalResponses).length}
                    <ListIcon />
                  </Box>
                </Tooltip>
              )}
              {(source.surveyType === 'custom' && !firstScaleQuestion) || (
                <div
                  className={styles.rating}
                  itemProp="reviewRating"
                  itemScope
                  itemType="http://schema.org/Rating"
                >
                  <meta itemProp="worstRating" content="0" />
                  <div className={styles['rating-value']} itemProp="ratingValue">
                    {source.surveyType === 'custom'
                      ? source.additionalResponses?.[firstScaleQuestion._id]
                      : source.score}
                  </div>
                  <meta itemProp="bestRating" content="10" />
                </div>
              )}
            </Box>
          }
        />
        <CardText className={styles.text} lang={source.language} dir={dir}>
          {typing.indexOf(source._id) > -1 ? <Typing /> : null}
          {source.surveyType === 'custom'
            ? firstOpenQuestion && source.additionalResponses?.[firstOpenQuestion._id]
            : source.comment}
          {source.translations && Object.keys(source.translations).length > 0
            ? Object.keys(source.translations).map(lang => (
                <div className={styles.translation} key={lang}>
                  <i>{source.translations[lang]}</i>
                  <small>
                    <FormattedMessage
                      id="DashboardListItem.Translated"
                      defaultMessage="Translated from {source} to {destination}"
                      values={{
                        source: surveyLanguages[locale][source.language],
                        destination: surveyLanguages[locale][lang],
                      }}
                    />
                  </small>
                </div>
              ))
            : null}
        </CardText>
      </CardActionArea>
      <CardActions>
        <Autocomplete
          fullWidth
          multiple
          freeSolo
          disableCloseOnSelect
          disableClearable
          size="small"
          style={{ marginLeft: '1em' }}
          value={currentTags}
          onChange={handleChange}
          options={allTags?.map(a => a.name) || []}
          getOptionLabel={option => option.title || option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title || option}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <IconButton
                    color="muted"
                    title={formatMessage(messages.autoTag)}
                    onClick={() => autotag(source.survey, source.id)}
                  >
                    <Settings />
                  </IconButton>
                ),
              }}
              variant="standard"
              placeholder={formatMessage(messages.addTags)}
              style={{ marginTop: 2, marginBottom: 2 }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option}
                size="small"
                {...getTagProps({ index })}
                className={styles.chip}
              />
            ))
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(option => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
        />
      </CardActions>
    </Card>
  );
};

SurveyResponseListItem.propTypes = {
  result: PropTypes.object.isRequired,
};

export default SurveyResponseListItem;

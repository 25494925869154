import { Avatar, Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import SurveyEmail from 'client/modules/Emails/Survey/SurveyEmail';
import AddResponsePage from 'client/modules/Response/pages/AddResponsePages/AddResponsePage';
import styles from 'client/modules/Surveys/pages/CustomizeSurvey/CustomizeEmail.css';
import { surveyMessages } from 'client/modules/Surveys/SurveyReducer';
import MailWindow from 'components/MailWindow/MailWindow';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { renderEmail } from 'react-html-email';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import { localizationData } from '../../../../../Intl/setup';

const MailPreview = (props) => {
  const { formatMessage } = useIntl();
  const iframeDocument = useRef();
  const iframe = useRef();

  const resizeIframe = () => {
    setTimeout(() => {
      if (iframeDocument.current) {
        const { offsetHeight } = iframeDocument.current.documentElement;
        if (offsetHeight) {
          iframe.current.style.height = `${offsetHeight + 20}px`;
        }
      }
    }, 100);
  };

  return (
    <Paper className={styles.preview}>
      <div className={styles.previewCover}>
        <FormattedMessage
          id="Survey.ThisIsAPreview"
          defaultMessage="This is a preview of the email we will send to your customers."
        />
      </div>
      {props.score === null ? (
        <MailWindow>
          <div className={styles.header}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Box>
                <Avatar sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  width: 30,
                  height: 30,
                  background: 'linear-gradient(225deg, rgba(160,166,182,1) 0%, rgba(144,146,158,1) 100%)',
                }}
                >
                  {props.fromWithDefault.split((' ')).map(([i]) => i?.toUpperCase()).slice(0, 2).join('')}
                </Avatar>
              </Box>
              <Box>
                <Box className={styles.from}><strong>{props.fromWithDefault}</strong></Box>
                <Box>{props.subjectWithDefault}</Box>
                <Box>
                  {formatMessage(surveyMessages['Survey.Reply'])}
                  <span className={styles.dim}>{props.replyTo}</span>
                </Box>
              </Box>
            </Box>
          </div>
          <Frame
            ref={iframe}
            className={styles.frame}
            contentDidMount={resizeIframe}
            contentDidUpdate={resizeIframe}
          >
            <FrameContextConsumer>
              {
                ({ document }) => {
                  iframeDocument.current = document;
                }
              }
            </FrameContextConsumer>
            <div
              role="none"
              onClick={props.onClick}
              style={{ zoom: 0.82 }}
              dangerouslySetInnerHTML={{
                __html: renderEmail(
                  <IntlProvider
                    locale={props.locale}
                    messages={localizationData[props.locale].messages}

                  >
                    <SurveyEmail
                      onClick={props.onClick}
                      preview={props.preview}
                      surveyType={props.surveyType}
                      surveyCsatType={props.surveyCsatType}
                      csatFacesNum={props.csatFacesNum}
                      brand={props.brand}
                      topColor={props.topColor}
                      buttonColor={props.buttonColor}
                      from={props.fromWithDefault}
                      replyTo={props.replyTo}
                      question={props.question}
                      intro={props.intro}
                      footer={props.footer}
                      subject={props.subjectWithDefault}
                      language={props.surveyLanguage || props.locale}
                      logoUrl={props.logoUrl ? `${props.logoUrl}?r=${props.rand}` : null}
                    />
                  </IntlProvider>,
                ),
              }}
            />
          </Frame>
        </MailWindow>
      ) : (
        <div style={{ zoom: 0.82 }}>
          <AddResponsePage
            language={props.surveyLanguage || props.locale}
            score={props.score}
            onSubmit={props.onSubmit}
            followUpQuestion={props.followUpQuestion}
            brand={props.brand}
            logoUrl={props.logoUrl}
            topColor={props.topColor}
          />
        </div>
      )}
    </Paper>
  );
};


MailPreview.propTypes = {
  fromWithDefault: PropTypes.string.isRequired,
  subjectWithDefault: PropTypes.string.isRequired,
  replyTo: PropTypes.any.isRequired,
  preview: PropTypes.bool,
  score: PropTypes.any,
  onClick: PropTypes.func,
  locale: PropTypes.any,
  surveyType: PropTypes.string,
  surveyCsatType: PropTypes.string,
  csatFacesNum: PropTypes.number,
  brand: PropTypes.any,
  topColor: PropTypes.any,
  buttonColor: PropTypes.any,
  question: PropTypes.string,
  intro: PropTypes.string,
  footer: PropTypes.string,
  surveyLanguage: PropTypes.any,
  logoUrl: PropTypes.any,
  rand: PropTypes.string,
  onSubmit: PropTypes.func,
  followUpQuestion: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default MailPreview;

import { ErrorMessage } from '@hookform/error-message';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import {
  Box, FormHelperText, MenuItem, Select, Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { darken } from '@mui/material/styles';
import { scale, selectMany, selectOne } from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import capitalizeFirstLetter from 'client/util/capitalizeFirstLetter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const styles = {
  logic: {
    position: 'relative',
    display: 'grid',
    border: '1px solid #e0e0d9',
    borderRadius: 2,
    p: 2,
    gridTemplateColumns: '1fr 2fr',
    gap: 1,
    alignItems: 'baseline',
    pr: 3,
  },
  between: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 2fr',
    gap: '0 0.5rem',
    alignItems: 'center',
  },
  and: {
    textAlign: 'center',
  },
  select: {
    width: '100%',
    backgroundColor: 'background.default',
    '& .MuiSelect-select': {
      p: 1,
    },
  },
  remove: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(50%, -50%)',
    backgroundColor: 'background.body',
    '&:hover': {
      backgroundColor: theme => darken(theme.palette.background.body, 0.05),
    },
  },
};

const messages = defineMessages({
  is: {
    id: 'EditQuestionLogic.is',
    defaultMessage: 'is',
  },
  isNot: {
    id: 'EditQuestionLogic.isNot',
    defaultMessage: 'is not',
  },
  isBetween: {
    id: 'EditQuestionLogic.isBetween',
    defaultMessage: 'is between',
  },
  isExactly: {
    id: 'EditQuestionLogic.isExactly',
    defaultMessage: 'is exactly',
  },
  includesAny: {
    id: 'EditQuestionLogic.includesAny',
    defaultMessage: 'includes any of the following',
  },
  includesAll: {
    id: 'EditQuestionLogic.includesAll',
    defaultMessage: 'includes all of the following',
  },
  doesntIncludeAny: {
    id: 'EditQuestionLogic.doesntIncludeAny',
    defaultMessage: "doesn't include any of the following",
  },
  hasAnyValue: {
    id: 'EditQuestionLogic.hasAnyValue',
    defaultMessage: 'has any value',
  },
  isEmpty: {
    id: 'EditQuestionLogic.isEmpty',
    defaultMessage: 'is empty',
  },
  errorRequired: {
    id: 'EditQuestionLogic.errors.Required',
    defaultMessage: 'Please select a value',
  },
  errorInvalidRange: {
    id: 'EditQuestionLogic.errors.InvalidRange',
    defaultMessage: 'Invalid range',
  },
  finishSurvey: {
    id: 'EditQuestionLogic.finishSurvey',
    defaultMessage: 'Finish survey',
  },
});

const renderErrorMessage = ({ message }) => {
  const { formatMessage } = useIntl();
  const error = capitalizeFirstLetter(message);
  if (!error) {
    return null;
  }
  return <FormHelperText error>{formatMessage(messages[`error${error}`])}</FormHelperText>;
};

const ValueControlScale = ({ index }) => {
  const { watch, trigger } = useFormContext();
  const operator = watch(`logics.${index}.operator`);
  const scaleMin = watch('scaleMin');
  const scaleMax = watch('scaleMax');
  const deps = [`logics.${index}.operator`];
  const options = Array.from({ length: scaleMax - scaleMin + 1 }, (v, i) => ({ value: i + scaleMin, label: i + scaleMin }));
  if (['is', 'is_not'].includes(operator)) {
    return (
      <>
        <Controller
          render={
            ({ field, formState: { errors } }) => (
              <>
                <Select sx={styles.select} {...field} multiple>
                  {options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                </Select>
                <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
              </>
            )
          }
          name={`logics.${index}.value`}
          rules={{ deps, required: 'Required' }}
        />
      </>
    );
  }
  if (operator === 'is_between') {
    const validate = (_, values) => {
      const [min, max] = get(values, `logics.${index}.value`, []);
      return max <= min ? 'InvalidRange' : true;
    };

    const fields = [0, 1].map(i => `logics.${index}.value.${i}`);
    const { errors: allErrors } = useFormState();
    return (
      <>
        <Box sx={styles.between}>
          <Controller
            render={
              ({ field }) => (
                <Select sx={styles.select} {...field}>
                  {options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                </Select>
              )

            }
            name={fields[0]}
            rules={{ deps, required: 'Required', validate }}
          />
          <Box sx={styles.and}><FormattedMessage id="EditQuestionLogic.and" defaultMessage="and" /></Box>
          <Controller
            render={
              ({ field }) => (
                <Select
                  sx={styles.select}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    return trigger(fields[0]);
                  }}
                >
                  {options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                </Select>
              )
            }
            name={fields[1]}
            rules={{ deps, required: 'Required' }}
          />
          <ErrorMessage errors={allErrors} name={fields[0]} render={renderErrorMessage} />
        </Box>
      </>
    );
  }
  return null;
};

ValueControlScale.propTypes = {
  index: PropTypes.number.isRequired,
};

const ValueControlOptions = ({ index }) => {
  const { watch } = useFormContext();
  const optionsAttributes = watch('optionsAttributes');
  return (
    <>
      <Controller
        render={
          ({ field, formState: { errors } }) => (
            <>
              <Select sx={styles.select} {...field} multiple>
                {optionsAttributes.map(o => <MenuItem key={o.text} value={o._id}>{o.text}</MenuItem>)}
              </Select>
              <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
            </>
          )
        }
        name={`logics.${index}.value`}
        rules={{ required: 'Required' }}
      />
    </>
  );
};

ValueControlOptions.propTypes = {
  index: PropTypes.number.isRequired,
};

const ValueControl = ({ index }) => {
  const { watch } = useFormContext();
  const type = watch('type');
  const Control = {
    [scale]: ValueControlScale,
    [selectOne]: ValueControlOptions,
    [selectMany]: ValueControlOptions,
  }[type];
  if (Control) {
    return <Control index={index} />;
  }
  return null;
};

ValueControl.propTypes = {
  index: PropTypes.number.isRequired,
};
const QuestionLogicCard = ({
  index, questions, questionIndex, remove,
}) => {
  const { watch, setValue } = useFormContext();
  const { formatMessage } = useIntl();
  const type = watch('type');
  const operatorOptions = {
    [scale]: [
      { value: 'is', label: formatMessage(messages.is) },
      { value: 'is_not', label: formatMessage(messages.isNot) },
      { value: 'is_between', label: formatMessage(messages.isBetween) },
    ],
    [selectOne]: [
      { value: 'is', label: formatMessage(messages.is) },
      { value: 'is_not', label: formatMessage(messages.isNot) },
    ],
    [selectMany]: [
      { value: 'is_exactly', label: formatMessage(messages.isExactly) },
      { value: 'includes_any', label: formatMessage(messages.includesAny) },
      { value: 'includes_all', label: formatMessage(messages.includesAll) },
      { value: 'doesnt_include_any', label: formatMessage(messages.doesntIncludeAny) },
    ],
  }[type];
  operatorOptions.push({ value: 'has_any_value', label: formatMessage(messages.hasAnyValue) });
  operatorOptions.push({ value: 'is_empty', label: formatMessage(messages.isEmpty) });
  const hasValue = !isNil(watch(`logics.${index}.value.0`));
  return (
    <Box sx={styles.logic}>
      <Box>
        <Typography><FormattedMessage id="EditQuestionLogic.ifAnswer" defaultMessage="If answer" /></Typography>
      </Box>
      <Box>
        <Controller
          render={
            ({ field, formState: { errors } }) => (
              <>
                <Select
                  sx={styles.select}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue(`logics.${index}.value`, []);
                  }}
                >
                  {operatorOptions.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                </Select>
                <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
              </>
            )
          }
          rules={{ required: true }}
          name={`logics.${index}.operator`}
        />
      </Box>
      <Box />
      <Box>
        <ValueControl index={index} />
      </Box>
      {hasValue && (
        <>
          <Box>
            <Typography><FormattedMessage id="EditQuestionLogic.then" defaultMessage="then" /></Typography>
          </Box>
          <Box>
            <Controller
              render={
                ({ field, formState: { errors } }) => (
                  <>
                    <Select
                      sx={styles.select}
                      {...field}
                    >
                      {questions.map((q, i) => (
                        <MenuItem key={q._id} value={q._id}>{`${i + 1}. - ${q.text}`}</MenuItem>
                      )).filter((q, i) => i > questionIndex)}
                      <MenuItem value="finishSurvey">
                        {formatMessage(messages.finishSurvey)}
                      </MenuItem>
                    </Select>
                    <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
                  </>
                )}
              name={`logics.${index}.action`}
              rules={{ required: 'Required' }}
            />
          </Box>
        </>
      )}
      <IconButton sx={styles.remove} onClick={remove}>
        <RemoveCircleOutline />
      </IconButton>
    </Box>
  );
};

QuestionLogicCard.propTypes = {
  index: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionIndex: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

export default QuestionLogicCard;

/* eslint-disable react/destructuring-assignment, jsx-a11y/click-events-have-key-events, jsx-a11y/label-has-for, react/require-default-props */
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import { setError, setOk } from 'client/modules/App/AppActions';
import MailPreview from 'client/modules/Surveys/pages/CustomizeSurvey/MailPreview';
import onMailPreviewClick from 'client/modules/Surveys/pages/CustomizeSurvey/onMailPreviewClick';
import slugify from 'client/util/slugify';
import FormsyText from 'components/FormsyText/FormsyText';
import FormsySwitch from 'components/FormsyToggle/FormsySwitch';
import SelectField from 'components/SelectField/SelectField';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ChromePicker from 'react-color/lib/components/chrome/Chrome';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { localizationData, surveyLanguages } from '../../../../../Intl/setup';
import Config from '../../../../../server/config';
import withLocation from '../../../../util/withLocation';
import { Accordion, AccordionDrawer } from '../../../App/components/Accordion';
import Upload from '../../../App/components/UploadS3';
import { getProfile, getToken } from '../../../Auth/AuthReducer';
import {
  customizationInputChange, fileUploaded, inputChange, postSurvey, setSurveySubmitEnabled, toggleButtonPicker, toggleTopPicker,
} from '../../SurveyActions';
import {
  canSubmit,
  getCustomization,
  getCustomizations,
  getDisplayButtonColorPicker,
  getDisplayTopColorPicker,
  getId,
  getInputValue,
  getLogoUrl,
  getRand,
  getSubmitButtonMessage,
  getSurveys,
  getSurveyType,
  surveyMessages,
} from '../../SurveyReducer';
import styles from './CustomizeEmail.css';

const messages = defineMessages({
  surveySaved: { id: 'Survey.Saved', defaultMessage: 'Survey saved successfully' },
});
const languageItems = locale => Object.keys(surveyLanguages[locale]).map(lang => (
  (<MenuItem key={lang} value={lang}>{surveyLanguages[locale][lang]}</MenuItem>)
));

const CustomizeEmail = (props) => {
  const navigate = useNavigate();
  const iframe = useRef();
  const [score, setScore] = useState(null);

  const { formatMessage, locale } = props.intl;

  const handleChange = (event) => {
    props.inputChange(event.target.id, event.target.value);
  };

  const handleSwitchChange = (event, checked) => {
    props.inputChange(event.target.id, checked);
  };

  const handleCustomizationChange = (event) => {
    props.customizationInputChange(event.target.id, event.target.value, props.surveyLanguage);
  };

  const handleChangeTopColor = (topColor) => {
    props.inputChange('topColor', topColor.hex);
  };

  const handleChangeButtonColor = (buttonColor) => {
    props.inputChange('buttonColor', buttonColor.hex);
  };

  const handleChangeLanguage = (event) => {
    props.inputChange('surveyLanguage', event.target.value);
  };

  const handleChangeCsatType = (event) => {
    props.inputChange('surveyCsatType', event.target.value);
  };

  const handleChangeCsatFacesNum = (event) => {
    props.inputChange('csatFacesNum', event.target.value);
  };

  const handleClose = () => {
    props.toggleTopPicker(false);
    props.toggleButtonPicker(false);
  };

  const handleOpenTopColorPicker = () => {
    props.toggleTopPicker();
  };

  const handleOpenButtonColorPicker = () => {
    props.toggleButtonPicker();
  };

  const enableButton = () => {
    props.setSurveySubmitEnabled(true);
  };

  const disableButton = () => {
    props.setSurveySubmitEnabled(false);
  };

  const handleSubmit = async () => {
    props.setSurveySubmitEnabled(false, formatMessage(surveyMessages['Survey.Submitting']));
    const {
      _id: id, topColor, buttonColor, brand, name, fromName, fromEmail, replyTo, logoUrl, customizations = [], surveyType, surveyCsatType, csatFacesNum, preventMultipleResponses,
    } = props;
    ['intro', 'subject'].forEach((p) => { // Convert deprecated default intro and subject to customizations
      const custom = `custom${p[0].toUpperCase()}${p.substring(1)}`;
      if (props[p] && !props[custom]) {
        customizations.push({
          value: props[p],
          name: p,
          language: props.surveyLanguage,
        });
      }
    });
    const properties = {
      id,
      topColor,
      buttonColor,
      brand,
      name,
      fromName,
      fromEmail,
      replyTo,
      customizations,
      subject: null,
      intro: null,
      language: props.surveyLanguage || locale,
      surveyType,
      surveyCsatType,
      csatFacesNum,
      preventMultipleResponses,
    };
    if (logoUrl) {
      properties.logoUrl = logoUrl;
    }
    const shouldStay = props.surveys?.length > 0;
    await props.postSurvey(properties, props.token, navigate, shouldStay);
    props.setOk(formatMessage(messages.surveySaved), 4000);
  };

  const handleFileLoad = (url, mainColor) => {
    setTimeout(() => props.fileUploaded(url), 100);
    if (mainColor) {
      props.inputChange('topColor', mainColor);
      props.inputChange('buttonColor', mainColor);
    }
  };

  const lang = props.surveyLanguage || locale;

  const repeatedName = props.surveys && props.surveys.filter(s => s.brand === props.brand && s.id !== props._id).length > 0;

  const basicFields = (
    <div>
      <FormsyText
        id="brand"
        name="brand"
        value={props.brand}
        label={formatMessage(surveyMessages['Survey.Brand'])}
        onChange={handleChange}
        required
        autoFocus
      />
      {props.surveyType !== 'custom' || (
        <FormsyText
          id="question"
          name="question"
          value={props.question}
          multiLine
          rows={3}
          label={formatMessage(surveyMessages['Survey.Question'])}
          onChange={handleCustomizationChange}
        />
      )}
      {repeatedName
        ? (
          <div>
            <br />
            <FormsyText
              id="name"
              name="name"
              value={props.name}
              hintText={formatMessage(surveyMessages['Survey.Name.Hint'])}
              label={formatMessage(surveyMessages['Survey.Name'])}
              onChange={handleChange}
              required
              multiLine
              rows={1}
            />
          </div>
        ) : null}
      <div className={styles.addLogo}>
        <Upload
          className={styles.upload}
          label={formatMessage(surveyMessages['Survey.AddLogo'])}
          onFileLoad={handleFileLoad}
          token={props.token}
          fileName={props._id ? props._id : undefined}
        />
        <span>{formatMessage(surveyMessages['Survey.AddLogoFormats'])}</span>
      </div>
      <div className={styles.pickerControl}>
        <label
          htmlFor="topColor"
          className={styles.label}
        >
          {formatMessage(surveyMessages['Survey.TopColor'])}
        </label>
        <div className={styles.swatch} role="button" tabIndex={0} onClick={handleOpenTopColorPicker}>
          <div id="topColor" className={styles.color} style={{ backgroundColor: props.topColor }} />
          <div className={styles.arrow}>▾</div>
        </div>
      </div>
      {props.displayTopColorPicker
        ? (
          <div className={styles.popover}>
            <div className={styles.cover} role="button" tabIndex={0} onClick={handleClose} />
            <ChromePicker color={props.topColor} disableAlpha onChange={handleChangeTopColor} />
          </div>
        ) : null}
      {props.surveyType === 'csat' && props.surveyCsatType === 'faces'
        ? null
        : (
          <div className={styles.pickerControl}>
            <label
              htmlFor="buttonColor"
              className={styles.label}
            >
              {formatMessage(surveyMessages['Survey.ButtonColor'])}
            </label>
            <div className={styles.swatch} role="button" tabIndex={0} onClick={handleOpenButtonColorPicker}>
              <div id="buttonColor" className={styles.color} style={{ backgroundColor: props.buttonColor }} />
              <div className={styles.arrow}>▾</div>
            </div>
          </div>
        )
      }
      {props.displayButtonColorPicker
        ? (
          <div className={styles.popover}>
            <div className={styles.cover} role="button" tabIndex={0} onClick={handleClose} />
            <ChromePicker color={props.buttonColor} disableAlpha onChange={handleChangeButtonColor} />
          </div>
        ) : null}
      <SelectField
        id="surveyLanguage"
        label={formatMessage(surveyMessages['Survey.BasesLanguage'])}
        maxHeight={200}
        value={props.surveyLanguage || locale}
        onChange={handleChangeLanguage}
      >
        {languageItems(locale || Config.defaultLanguage)}
      </SelectField>
      {props.surveyType === 'csat'
        ? (
          <SelectField
            id="surveyCsatType"
            label={formatMessage(surveyMessages.csatType)}
            maxHeight={200}
            value={props.surveyCsatType}
            onChange={handleChangeCsatType}
          >
            <MenuItem key="faces" value="faces">{formatMessage(surveyMessages.csatTypeFaces)}</MenuItem>
            <MenuItem key="numbers" value="numbers">{formatMessage(surveyMessages.csatTypeNumbers)}</MenuItem>
          </SelectField>
        )
        : null}
      {props.surveyType === 'csat' && props.surveyCsatType === 'faces'
        ? (
          <div className={styles.faceSlider}>
            <label
              htmlFor="csatFacesNum"
              className={styles.label}
            >
              {formatMessage(surveyMessages.csatFacesNum)}
            </label>
            <Slider
              classes={{ root: styles.sliderRoot }}
              marks={[2, 3, 4, 5].map(n => ({ value: n, label: `${n}` }))}
              className={styles.slider}
              name="csatFacesNum"
              min={2}
              max={5}
              step={1}
              value={props.csatFacesNum}
              onChange={handleChangeCsatFacesNum}
            />
          </div>
        )
        : null
      }
    </div>
  );

  return (
    <Grid className={styles['main-grid']} style={props.style} fluid>
      <Row className={styles['main-content']}>
        <Col className={styles.controls} xs={12} sm={5} md={4} lg={4}>
          <Helmet title={formatMessage(surveyMessages['Survey.Personalize.Title'])} />
          <Paper className={styles.form}>
            <Formsy.Form
              onValid={enableButton}
              onInvalid={disableButton}
              onValidSubmit={handleSubmit}
            >
              {props._id
                ? (
                  <Accordion startPosition={0}>
                    <AccordionDrawer key="acc-basic" title={formatMessage(surveyMessages['Survey.BasicCustomization'])}>
                      {basicFields}
                    </AccordionDrawer>
                    <AccordionDrawer
                      key="acc-advanced"
                      title={formatMessage(surveyMessages['Survey.AdvancedCustomization'])}
                    >
                      <FormsyText
                        id="fromName"
                        name="fromName"
                        value={props.fromName}
                        label={formatMessage(surveyMessages['Survey.From'])}
                        onChange={handleChange}
                      />
                      <FormsyText
                        id="replyTo"
                        name="replyTo"
                        value={props.replyTo}
                        label={formatMessage(surveyMessages['Survey.Reply'])}
                        onChange={handleChange}
                        validations="isEmail"
                        validationError={formatMessage(surveyMessages['validations.email'])}
                      />
                      <FormsyText
                        id="question"
                        name="question"
                        value={props.question}
                        multiline
                        label={formatMessage(surveyMessages['Survey.Question'])}
                        onChange={handleCustomizationChange}
                      />
                      <FormsyText
                        id="subject"
                        name="subject"
                        value={props.subject}
                        multiline
                        label={formatMessage(surveyMessages['Survey.Subject'])}
                        onChange={handleCustomizationChange}
                      />
                      <FormsyText
                        id="intro"
                        name="intro"
                        value={props.intro}
                        multiline
                        label={formatMessage(surveyMessages['Survey.Intro'])}
                        onChange={handleCustomizationChange}
                      />
                      <FormsyText
                        id="footer"
                        name="footer"
                        value={props.footer}
                        multiline
                        label={formatMessage(surveyMessages['Survey.Footer'])}
                        onChange={handleCustomizationChange}
                      />
                      <FormsyText
                        id="followUpQuestion"
                        name="followUpQuestion"
                        value={props.followUpQuestion}
                        multiline
                        hintText={formatMessage(surveyMessages['nps.tell_us_more'])}
                        label={formatMessage(surveyMessages['Survey.FollowUpQuestion'])}
                        onChange={handleCustomizationChange}
                      />
                      <FormsySwitch
                        id="preventMultipleResponses"
                        name="preventMultipleResponses"
                        value={props.preventMultipleResponses}
                        label={formatMessage(surveyMessages['Survey.PreventMultipleResponses'])}
                        onChange={handleSwitchChange}
                      />
                    </AccordionDrawer>
                  </Accordion>
                ) : (
                  <div style={{ padding: '16px' }}>
                    {basicFields}
                  </div>
                )
              }
              <Button
                type="submit"
                variant="contained"
                className={styles.submit}
                disabled={!props.canSubmit}
                color="primary"
              >
                {(
                  (props.submitButtonMessage || props._id || props.surveys.length > 0)
                    ? formatMessage(surveyMessages['Survey.Save'])
                    : formatMessage(surveyMessages['Survey.Next'])
                )}
              </Button>
            </Formsy.Form>
          </Paper>
        </Col>
        <Col xs={12} sm={7} md={8} lg={8}>
          <MailPreview
            preview
            key={lang}
            fromWithDefault={props.fromWithDefault}
            subjectWithDefault={props.subjectWithDefault}
            replyTo={props.replyTo}
            score={score}
            ref={iframe}
            onClick={onMailPreviewClick(setScore)}
            locale={lang}
            surveyType={props.surveyType}
            surveyCsatType={props.surveyCsatType}
            csatFacesNum={props.csatFacesNum}
            brand={props.brand}
            topColor={props.topColor}
            buttonColor={props.buttonColor}
            question={props.question}
            intro={props.intro}
            footer={props.footer}
            surveyLanguage={props.surveyLanguage}
            logoUrl={props.logoUrl}
            rand={props.rand}
            onSubmit={() => setScore(null)}
            followUpQuestion={props.followUpQuestion}
          />
        </Col>
      </Row>
    </Grid>
  );
};

// Retrieve data from store as props
function mapStateToProps(state, ownProps) {
  let fromEmail = slugify(getInputValue('brand', state));
  if (fromEmail === null || fromEmail.length < 1) {
    fromEmail = 'surveys';
  }
  const brandWithDefault = getInputValue('brand', state) || ownProps.intl.formatMessage(surveyMessages['Survey.Brand']);
  const customSubject = getCustomization('subject', false, state);
  const surveyType = getSurveyType(state) || ownProps.location.query.type || 'nps';
  const getDefaultSubject = () => {
    const locale = getInputValue('surveyLanguage', state) || state.app.i18n.locale;
    const ld = localizationData[locale];
    if (ld && ld.messages && ld.messages[`${surveyType}.subject`]) {
      return ld.messages[`${surveyType}.subject`];
    }
    if (surveyType === 'custom') {
      return '';
    }
    if (ld && ld.messages && ld.messages[`${surveyType}.question`]) {
      return ld.messages[`${surveyType}.question`];
    }
    // Should never reach here: return the question in the current language instead of the survey language
    return ownProps.intl.formatMessage(surveyMessages[`${surveyType}.question`]);
  };

  const _id = getId(state);
  const buttonColor = getInputValue('buttonColor', state) || (!_id && surveyType === 'star_five' ? '#f6d400' : '#333');
  const topColor = getInputValue('topColor', state) || '#333';
  return {
    canSubmit: canSubmit(state),
    displayTopColorPicker: getDisplayTopColorPicker(state),
    displayButtonColorPicker: getDisplayButtonColorPicker(state),
    _id,
    topColor,
    buttonColor,
    brand: getInputValue('brand', state),
    name: getInputValue('name', state),
    fromName: getInputValue('fromName', state),
    fromWithDefault: getInputValue('fromName', state) || brandWithDefault,
    fromEmail: `${fromEmail}@promoterninja.com`,
    replyTo: getInputValue('replyTo', state) || getProfile(state).email,
    question: getCustomization('question', false, state),
    subject: getCustomization('subject', false, state) || getInputValue('subject', state),
    customSubject,
    subjectWithDefault: (customSubject || getInputValue('subject', state) || getDefaultSubject()).replace('{brand}', brandWithDefault).replace('{marca}', brandWithDefault),
    intro: getCustomization('intro', false, state) || getInputValue('intro', state),
    footer: getCustomization('footer', false, state) || getInputValue('footer', state),
    customIntro: getCustomization('intro', false, state),
    customFooter: getCustomization('footer', false, state),
    surveyLanguage: getInputValue('surveyLanguage', state),
    token: getToken(state),
    submitButtonMessage: getSubmitButtonMessage(state),
    surveys: getSurveys(state),
    logoUrl: getLogoUrl(state),
    rand: `${getRand(state)}`,
    customizations: getCustomizations(state),
    surveyType,
    surveyCsatType: getInputValue('surveyCsatType', state) || 'faces',
    csatFacesNum: getInputValue('csatFacesNum', state) || 5,
    followUpQuestion: getCustomization('followUpQuestion', false, state),
    preventMultipleResponses: getInputValue('preventMultipleResponses', state) || false,
  };
}

CustomizeEmail.propTypes = {
  intl: PropTypes.object,
  displayTopColorPicker: PropTypes.bool.isRequired,
  displayButtonColorPicker: PropTypes.bool.isRequired,
  _id: PropTypes.string,
  topColor: PropTypes.string,
  buttonColor: PropTypes.string,
  surveyLanguage: PropTypes.string,
  brand: PropTypes.string,
  name: PropTypes.string,
  fromName: PropTypes.string,
  fromWithDefault: PropTypes.string,
  fromEmail: PropTypes.string,
  replyTo: PropTypes.string,
  question: PropTypes.string,
  subject: PropTypes.string,
  subjectWithDefault: PropTypes.string,
  surveys: PropTypes.array,
  intro: PropTypes.string,
  footer: PropTypes.string,
  logoUrl: PropTypes.string,
  rand: PropTypes.string,
  canSubmit: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  submitButtonMessage: PropTypes.string,
  postSurvey: PropTypes.func.isRequired,
  setSurveySubmitEnabled: PropTypes.func.isRequired,
  toggleTopPicker: PropTypes.func.isRequired,
  toggleButtonPicker: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  customizations: PropTypes.array,
  customizationInputChange: PropTypes.func.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyCsatType: PropTypes.string.isRequired,
  csatFacesNum: PropTypes.number.isRequired,
  fileUploaded: PropTypes.func.isRequired,
  followUpQuestion: PropTypes.string.isRequired,
  preventMultipleResponses: PropTypes.bool,
  setOk: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setError(msg) {
    dispatch(setError(msg));
  },
  setOk(msg, timeout) {
    dispatch(setOk(msg, timeout));
  },
  setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage) {
    dispatch(setSurveySubmitEnabled(canSubmitEnabled, submitButtonMessage));
  },
  postSurvey(model, token, navigate, shouldStay) {
    dispatch(postSurvey(model, token, navigate, shouldStay));
  },
  toggleTopPicker(show) {
    dispatch(toggleTopPicker(show));
  },
  toggleButtonPicker(show) {
    dispatch(toggleButtonPicker(show));
  },
  inputChange(id, value) {
    dispatch(inputChange(id, value));
  },
  fileUploaded(url) {
    dispatch(fileUploaded(url));
  },
  customizationInputChange(name, value, language) {
    dispatch(customizationInputChange(name, value, language, false));
  },
});

export default injectIntl(withLocation(connect(mapStateToProps, mapDispatchToProps)(CustomizeEmail)));
